import PrismicDOM from 'prismic-dom'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import ImageManager from './ImageManager'

/**
 * 
 * @param uid Prismic Block UID 
 * @param block Prismic Block Data
 * @returns Array<ComponentRendererCompatibleComponent>
 */
export const blockProcessor = (uid: string, block: any) => {
  switch (uid) {
    case 'store-locator-widget': {
      try {
        return {
          component: 'store_locator_widget',
          item: {
            image: {
              url: ImageManager.convert(block.block_image.url),
              alt: block.block_image.alt,
              width: block.block_image.dimensions.width,
              height: block.block_image.dimensions.height,
              filter: block.block_image_filter ? block.block_image_filter : ''
            }
          }
        }
      } catch (error) {
        return {
          component: 'store_locator_widget_error'
        }
      }
    }
    case 'collections-thumbs': {
      try {
        return {
          component: 'collections_thumbs',
          items: block.body[0].items
            .map(collection => {
              return {
                title: collection.block_title,
                subtitle: collection.block_subtitle,
                image: collection.block_image && collection.block_image.url ? {
                  url: ImageManager.convert(collection.block_image.url),
                  alt: collection.block_image.alt,
                  width: collection.block_image.dimensions.width,
                  height: collection.block_image.dimensions.height,
                  filter: collection.block_image_filter ? collection.block_image_filter : ''
                } : null,
                link: collection.block_link ? collection.block_link : ''
              }
            })
        }
      } catch (error) {
        return {
          component: 'collections_thumbs_error'
        }
      }
    }
    case 'tagline-horizontal-auto-scroll': {
      try {
        return {
          component: 'tagline_horizontal_auto_scroll',
          item: {
            title: block.block_title,
          }
        }
      } catch (error) {
        return {
          component: 'tagline_horizontal_auto_scroll_error'
        }
      }
    }
    case 'tagline-horizontal-auto-scroll-pantone': {
      try {
        return {
          component: 'tagline_horizontal_auto_scroll_pantone',
          item: {
            title: block.block_title,
          }
        }
      } catch (error) {
        return {
          component: 'tagline_horizontal_auto_scroll_pantone_error'
        }
      }
    }
    case 'baseline-gif': {
      try {
        return {
          component: 'baseline_gif',
          item: {
            title: block.block_title,
            link: block.block_link ? block.block_link : '',
          },
          items: block.body[0].items
            .map(image => {
              return image.block_image && image.block_image.url ? {
                url: ImageManager.convert(image.block_image.url),
                alt: image.block_image.alt,
                width: image.block_image.dimensions.width,
                height: image.block_image.dimensions.height,
                filter: image.block_image_filter ? image.block_image_filter : ''
              } : null
            })
        }
      } catch (error) {
        return {
          component: 'baseline_gif_error'
        }
      }
    }

    case 'top-message': {
      try {
        return {
          component: 'top_message',
          item: {
            text: block.block_text && block.block_text.length ? PrismicDOM.RichText.asHtml(block.block_text) : null,
            expand: block.block_text_expand && block.block_text_expand.length ? PrismicDOM.RichText.asHtml(block.block_text_expand) : null,
            returningUserMsg: block.returning_user_message && block.returning_user_message.length ? PrismicDOM.RichText.asHtml(block.returning_user_message) : null,
          }
        }
      } catch (error) {
        return {
          component: 'top_message_error'
        }
      }
    }

    default: {
      return {
        component: 'inexistant_block_error'
      }
    }
  }
}
/**
 * SubComponent processor, avoid using this pattern whenever possible
 * @param component SubComponent from the Prismic page
 * @returns Array<ComponentRendererCompatibleComponent>
 */
export const subComponentProcessor = (component, slice) => {
  switch (component.slice_type) {
    case 'download_form_catalog': {
      try {
        return {
          component: 'download_form_catalog',
          item: {
            showFullForm: slice.primary.block_full_form === 'true'
          },
          items: component.items.map(item => ({
            url: item.block_catalog.url,
            locale: item.block_locale
          })),
        }
      } catch (error) {
        return {
          component: 'download_form_catalog_error'
        }
      }
    }
    default: {
      return {
        component: 'inexistant_sub_component'
      }
    }
  }
}
/**
 * Main processor for prismic components
 * @param page Prismic page queried from the prismic api
 * @returns Array<ComponentRendererCompatibleComponent>
 */
export const componentProcessor = (page: any) => {
  const storeView = currentStoreView()
  const storeCode = storeView.storeCode
  const locale = storeView.i18n.defaultLocale
  page = page || { content: [] }
  const content = page.content
    .map((slice) => {

      delete slice.page_name
      delete slice.page_description

      switch (slice.slice_type) {
        case 'interactive_highlight_slider': {
          try {
            let assets = [];
            for (let i = 1; i <= 4; i++) {
              let asset_img = slice.primary['block_' + i + '_image'];
              let asset_img_mobile = slice.primary['block_' + i + '_image_mobile'];
              if (asset_img && asset_img.url) {
                assets.push({
                  image: asset_img && asset_img.url ? {
                    url: ImageManager.convert(asset_img.url),
                    alt: asset_img.alt,
                    width: asset_img.dimensions.width,
                    height: asset_img.dimensions.height
                  } : null,
                  image_mobile: asset_img_mobile && asset_img_mobile.url ? {
                    url: ImageManager.convert(asset_img_mobile.url),
                    alt: asset_img_mobile.alt,
                    width: asset_img_mobile.dimensions.width,
                    height: asset_img_mobile.dimensions.height
                  } : null,
                  ctaLink: slice.primary['block_' + i + '_link'] ? slice.primary['block_' + i + '_link'] : ''
                });
              }
            }

            return {
              component: slice.slice_type,
              items: assets
            }
          } catch (error) {
            return {
              component: 'interactive_highlight_slider_error'
            }
          }
        }

        case 'slider_full_page': {
          try {
            return {
              component: slice.slice_type,
              items: slice.items.map(item => {
                return {
                  type: item.block_image && item.block_image.url ? 'image' : 'video',
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                    filter: item.block_image_filter ? item.block_image_filter : ''
                  } : null,
                  video: item.block_video && item.block_video.url ? {
                    url: ImageManager.convert(item.block_video.url, 'video'),
                    alt: item.block_video.name
                  } : null,
                  title: item.block_title,
                  subtitle: item.block_subtitle,
                  ctaText: item.block_cta_text,
                  ctaLink: item.block_cta_link ? item.block_cta_link : ''
                }
              })
            }
          } catch (error) {
            return {
              component: 'slider_full_page_error'
            }
          }
        }

        /* @TODO : Ajouter une image dans le contenu (signature) */
        case 'immersive_slider': {
          try {
            return {
              component: slice.slice_type,
              items: slice.items.map(item => {
                return {
                  overlay: item.block_overlay && item.block_overlay.url ? {
                    url: ImageManager.convert(item.block_overlay.url),
                    alt: item.block_overlay.alt,
                    width: item.block_overlay.dimensions.width,
                    height: item.block_overlay.dimensions.height,
                    filter: item.block_overlay_filter ? item.block_overlay_filter : ''
                  } : null,
                  type: item.block_image && item.block_image.url ? 'image' : 'video',
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                    filter: item.block_image_filter ? item.block_image_filter : ''
                  } : null,
                  video: item.block_video && item.block_video.url && item.block_video.kind === 'document' ? {
                    url: ImageManager.convert(item.block_video.url, 'video'),
                    alt: item.block_video.name
                  } : null,
                  title: item.block_title,
                  quote: item.block_quote,
                  description: item.block_description ? PrismicDOM.RichText.asHtml(item.block_description) : null
                }
              })
            }
          } catch (error) {
            return {
              component: 'immersive_slider_error'
            }
          }
        }

        case 'title_text_center': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
              },
            }
          } catch (error) {
            return {
              component: 'title_text_center_error'
            }
          }
        }

        case 'title_text': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                title_align: slice.primary.block_title_alignment,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                text_align: slice.primary.block_text_alignment,
              },
            }
          } catch (error) {
            return {
              component: 'title_text_error'
            }
          }
        }

        case 'headline_title_text': {
          try {
            return {
              component: 'section_headline_title_text',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                title_align: slice.primary.block_title_alignment,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                text_align: slice.primary.block_text_alignment,
              },
            }
          } catch (error) {
            return {
              component: 'headline_title_text_error'
            }
          }
        }

        case 'title_image': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                title_align: slice.primary.block_title_alignment,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  legend: slice.primary.block_image_legend,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
              },
            }
          } catch (error) {
            return {
              component: 'title_image_error'
            }
          }
        }

        case 'quote': {
          try {
            return {
              component: slice.slice_type,
              item: {
                link: slice.primary.block_link,
                text: slice.primary.block_text,
                footer: slice.primary.block_author
              }
            }
          } catch (error) {
            return {
              component: 'quote_error'
            }
          }
        }

        case 'basic_page_title': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                subtitle: slice.primary.block_subtitle,
              },
            }
          } catch (error) {
            return {
              component: 'basic_page_title_error'
            }
          }
        }
        case 'title_text_image': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                title_align: slice.primary.block_title_alignment,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                text_align: slice.primary.block_text_alignment,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                image_align: slice.primary.block_image_alignment,
              },
            }
          } catch (error) {
            return {
              component: 'title_text_image_error'
            }
          }
        }
        case 'store_locator_widget': {
          try {
            return {
              component: slice.slice_type,
            }
          } catch (error) {
            return {
              component: 'store_locator_widget_error'
            }
          }
        }

        case 'tagline_horizontal_auto_scroll': {
          try {
            return {
              component: slice.slice_type,
            }
          } catch (error) {
            return {
              component: 'tagline_horizontal_auto_scroll_error'
            }
          }
        }

        case 'tagline_horizontal_auto_scroll_pantone': {
          try {
            return {
              component: slice.slice_type,
            }
          } catch (error) {
            return {
              component: 'tagline_horizontal_auto_scroll_pantone_error'
            }
          }
        }

        case 'collections_thumbs': {
          try {
            return {
              component: slice.slice_type,
            }
          } catch (error) {
            return {
              component: 'collections_thumbs_error'
            }
          }
        }


        case 'baseline_gif': {
          try {
            return {
              component: slice.slice_type,
            }
          } catch (error) {
            return {
              component: 'baseline_gif_error'
            }
          }
        }

        case 'video_container': {
          try {
            return {
              component: slice.slice_type,
              item: {
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                videoMobile: slice.primary.block_video_mobile && slice.primary.block_video_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_video_mobile.url, 'video'),
                  alt: slice.primary.block_video_mobile.name
                } : null,
                title: slice.primary.block_title,
                subtitle: slice.primary.block_subtitle,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link ? slice.primary.block_cta_link : '',
                impolicy: slice.primary.impolicy
              }
            }
          } catch (error) {
            return {
              component: 'video_container_error'
            }
          }
        }

        case 'slider_limited_edition': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                subtitle: slice.primary.block_subtitle,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link ? slice.primary.block_cta_link : '',
              },
              items: slice.items.map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                    filter: item.block_image_filter ? item.block_image_filter : ''
                  } : null,
                  title: item.block_title,
                  subtitle: item.block_subtitle,
                  link: item.block_link ? item.block_link : ''
                }
              })
            }
          } catch (error) {
            return {
              component: 'slider_limited_edition_error'
            }
          }
        }

        case 'deep_dive': {
          try {
            return {
              component: slice.slice_type,
              item: {
                type: slice.primary.block_image && slice.primary.block_image.url ? 'image' : 'video',
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                title: slice.primary.block_title,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link ? slice.primary.block_cta_link : '',
              }
            }
          } catch (error) {
            return {
              component: 'deep_dive_error'
            }
          }
        }

        case 'service_thumbs': {
          try {
            return {
              component: slice.slice_type,
              items: slice.items.map(item => {
                return {
                  title: item.block_title,
                  text: item.block_text ? PrismicDOM.RichText.asHtml(item.block_text) : null,
                  ctaText: item.block_cta_text,
                  ctaLink: item.block_cta_link ? item.block_cta_link : '',
                  icon: item.block_icon
                }
              })
            }
          } catch (error) {
            return {
              component: 'service_thumbs_error'
            }
          }
        }

        case 'video_gif': {
          try {
            return {
              component: slice.slice_type,
              item: {
                type: slice.primary.block_image && slice.primary.block_image.url ? 'image' : 'video',
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
              }
            }
          } catch (error) {
            return {
              component: 'video_gif_error'
            }
          }
        }

        case 'baseline_gif': {
          try {
            return {
              component: slice.slice_type,
              item: {
                type: slice.primary.block_image && slice.primary.block_image.url ? 'image' : 'video',
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                title: slice.primary.block_title,
              }
            }
          } catch (error) {
            return {
              component: 'baseline_gif_error'
            }
          }
        }

        case 'hero_static_banner': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                headline: slice.primary.block_headline,
                text: slice.primary.block_text,
                align: slice.primary.block_align,
                scrolldown: slice.primary.block_scrolldown,
                type: slice.primary.block_image && slice.primary.block_image.url ? 'image' : 'video',
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null
              }
            }
          } catch (error) {
            return {
              component: 'hero_static_banner_error'
            }
          }
        }

        case 'moc_hero_static_banner': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                headline: slice.primary.block_headline,
                text: slice.primary.block_text,
                align: slice.primary.block_align,
                scrolldown: slice.primary.block_scrolldown,
                type: slice.primary.block_image && slice.primary.block_image.url ? 'image' : 'video',
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null
              }
            }
          } catch (error) {
            return {
              component: 'hero_static_banner_error'
            }
          }
        }

        case 'image_under_text': {
          try {
            return {
              component: slice.slice_type,
              item: {
                text: slice.primary.block_text
              },
              items: slice.items.map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                    filter: item.block_image_filter ? item.block_image_filter : ''
                  } : null
                }
              })
            }
          } catch (error) {
            return {
              component: 'image_under_text_error'
            }
          }
        }

        case 'edito_text_image': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                direction: slice.primary.block_direction,
                image: {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                },
                ctaText: slice.primary.block_cta_text,
                ctaScreenreader: slice.primary.block_cta_screenreader,
                ctaLink: slice.primary.block_cta_link ? slice.primary.block_cta_link : ''
              }
            }
          } catch (error) {
            return {
              component: 'edito_text_image_error'
            }
          }
        }

        case 'text': {
          try {
            return {
              component: slice.slice_type,
              item: {
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
              }
            }
          } catch (error) {
            return {
              component: 'text_error'
            }
          }
        }

        // @TODO Small Text
        case 'fullscreen_image_banner': {
          try {
            return {
              component: slice.slice_type,
              item: {
                collection: slice.primary.block_collection,
                line: slice.primary.block_line,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link ? slice.primary.block_cta_link : '',
                smallText: slice.primary.block_small_text,
                image: {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                }
              }
            }
          } catch (error) {
            return {
              component: 'fullscreen_image_banner_error'
            }
          }
        }

        case 'cta': {
          try {
            return {
              component: slice.slice_type,
              item: {
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link ? slice.primary.block_cta_link : '',
                ctaStroke: slice.primary.block_cta_skroke
              }
            }
          } catch (error) {
            return {
              component: 'cta_error'
            }
          }
        }

        case 'video': {
          try {
            return {
              component: slice.slice_type,
              item: {
                type: slice.primary.block_image && slice.primary.block_image.url ? 'image' : 'video',
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null
              }
            }
          } catch (error) {
            return {
              component: 'video_error'
            }
          }
        }

        case 'youtube_embed': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                title_align: slice.primary.block_title_alignment,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                text_align: slice.primary.block_text_alignment,
                video_id: slice.primary.block_video_id
              }
            }
          } catch (error) {
            return {
              component: 'youtube_embed_error'
            }
          }
        }

        case 'html_embed': {
          try {
            return {
              component: slice.slice_type,
              item: {
                content: slice.primary.block_html ? PrismicDOM.RichText.asText(slice.primary.block_html) : ''
              }
            }
          } catch (error) {
            return {
              component: 'html_embed_error'
            }
          }
        }

        // case 'news_related_product': {
        //   try {
        //     const { sku, name, media_gallery_entries } = slice.primary.block_product.data.product.product[storeCode]
        //     return {
        //       component: slice.slice_type,
        //       item: {
        //         routeParam: slice.primary.block_product.data.uid,
        //         sku,
        //         name,
        //         images: media_gallery_entries
        //           .filter(({ media_type }) => media_type === 'image')
        //           .map(({ file }) => ({
        //             src: `${config.magento2.imgUrl}${file}`,
        //             alt: 'product_picture'
        //           }))
        //       }
        //     }
        //   } catch (error) {
        //     return {
        //       component: 'news_related_product_error'
        //     }
        //   }
        // }

        case 'faq_category': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                image: {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                },
              },
              items: slice.items.map(({ block_question, block_answer, block_answer_rich }) =>
                ({ question: block_question, answer: block_answer_rich && PrismicDOM.RichText.asHtml(block_answer_rich).length ? PrismicDOM.RichText.asHtml(block_answer_rich) : block_answer }))
            }
          } catch (error) {
            return {
              component: 'faq_category_error'
            }
          }
        }

        case 'product_slider': {

          try {
            const itemsToMap = slice.primary.isextrem_e ? slice.items : slice.items.filter(({ block_product }) => Object.keys(block_product).length)
            return {
              component: slice.slice_type,
              item: {
                headline: slice.primary.block_headline ? slice.primary.block_headline : null,
                title: slice.primary.block_title,
                isExtremeE: slice.primary.isextrem_e
              },
              items: itemsToMap.map(item => {
                if (item.block_product && item.block_product[storeCode]) {
                  let { sku, name, status, price, image, secondary_image } = item.block_product[storeCode]
                  return {
                    headline: item.product_headline,
                    cta: item.product_cta,
                    availableDate: item.available_date,
                    raceDate: item.race_date,
                    waitingImage: {
                      image: item.waiting_image && item.waiting_image.url ? {
                        url: ImageManager.convert(item.waiting_image.url),
                        alt: item.waiting_image.alt,
                        width: item.waiting_image.dimensions.width,
                        height: item.waiting_image.dimensions.height,
                      } : null,
                      imageMobile: item.waiting_image_mobile && item.waiting_image_mobile.url ? {
                        url: ImageManager.convert(item.waiting_image_mobile.url),
                        alt: item.waiting_image_mobile.alt,
                        width: item.waiting_image_mobile.dimensions.width,
                        height: item.waiting_image_mobile.dimensions.height,
                      } : null
                    },
                    routeParam: item.uid,
                    sku,
                    name,
                    status,
                    price: price ? price : item.product_price,
                    image: ImageManager.convert(`${config.magento2.imgUrl}${image}`, 'image', 'product'),
                    imageHover: (secondary_image) ? ImageManager.convert(`${config.magento2.imgUrl}${secondary_image}`, 'image', 'product') : null,
                    raw: item.block_product[storeCode]
                  }
                } else if (slice.primary.isextrem_e) {
                  return {
                    headline: item.product_headline,
                    cta: item.product_cta,
                    availableDate: item.available_date,
                    raceDate: item.race_date,
                    waitingImage: {
                      image: item.waiting_image && item.waiting_image.url ? {
                        url: ImageManager.convert(item.waiting_image.url),
                        alt: item.waiting_image.alt,
                        width: item.waiting_image.dimensions.width,
                        height: item.waiting_image.dimensions.height,
                      } : null,
                      imageMobile: item.waiting_image_mobile && item.waiting_image_mobile.url ? {
                        url: ImageManager.convert(item.waiting_image_mobile.url),
                        alt: item.waiting_image_mobile.alt,
                        width: item.waiting_image_mobile.dimensions.width,
                        height: item.waiting_image_mobile.dimensions.height,
                      } : null
                    },
                    routeParam: item.uid,
                    price: item.product_price,
                    name: item.product_name,
                    status: 1
                  }
                }
                return null
              })
            }
          } catch (error) {
            return {
              component: 'product_slider_error',
            }
          }
        }

        case 'product_related_slider': {
          try {
            const itemsToMap = page.product.product[storeCode].relatedProductsIds
            return {
              component: slice.slice_type,
              item: {
                headline: slice.primary.block_headline ? slice.primary.block_headline : null,
                title: slice.primary.block_title,
                isExtremeE: slice.primary.isextrem_e
              },
              items: itemsToMap.map(item => {
                if (item.block_product && item.block_product[storeCode]) {
                  let { sku, name, status, price, image, secondary_image } = item.block_product[storeCode]
                  return {
                    headline: item.product_headline,
                    cta: item.product_cta,
                    availableDate: item.available_date,
                    raceDate: item.race_date,
                    waitingImage: {
                      image: item.waiting_image && item.waiting_image.url ? {
                        url: ImageManager.convert(item.waiting_image.url),
                        alt: item.waiting_image.alt,
                        width: item.waiting_image.dimensions.width,
                        height: item.waiting_image.dimensions.height,
                      } : null,
                      imageMobile: item.waiting_image_mobile && item.waiting_image_mobile.url ? {
                        url: ImageManager.convert(item.waiting_image_mobile.url),
                        alt: item.waiting_image_mobile.alt,
                        width: item.waiting_image_mobile.dimensions.width,
                        height: item.waiting_image_mobile.dimensions.height,
                      } : null
                    },
                    routeParam: item.uid,
                    sku,
                    name,
                    status,
                    price: price ? price : item.product_price,
                    image: ImageManager.convert(`${config.magento2.imgUrl}${image}`, 'image', 'product'),
                    imageHover: (secondary_image) ? ImageManager.convert(`${config.magento2.imgUrl}${secondary_image}`, 'image', 'product') : null,
                    raw: item.block_product[storeCode]
                  }
                } else if (slice.primary.isextrem_e) {
                  return {
                    headline: item.product_headline,
                    cta: item.product_cta,
                    availableDate: item.available_date,
                    raceDate: item.race_date,
                    waitingImage: {
                      image: item.waiting_image && item.waiting_image.url ? {
                        url: ImageManager.convert(item.waiting_image.url),
                        alt: item.waiting_image.alt,
                        width: item.waiting_image.dimensions.width,
                        height: item.waiting_image.dimensions.height,
                      } : null,
                      imageMobile: item.waiting_image_mobile && item.waiting_image_mobile.url ? {
                        url: ImageManager.convert(item.waiting_image_mobile.url),
                        alt: item.waiting_image_mobile.alt,
                        width: item.waiting_image_mobile.dimensions.width,
                        height: item.waiting_image_mobile.dimensions.height,
                      } : null
                    },
                    routeParam: item.uid,
                    price: item.product_price,
                    name: item.product_name,
                    status: 1
                  }
                }
                return null
              })
            }
          } catch (error) {
            return {
              component: 'product_related_slider_error',
            }
          }
        }

        case 'straps_compatible_watches_slider': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                product: page.product.product[storeCode]
              }
            }
          } catch (error) {
            return {
              component: 'straps_slider_error',
            }
          }
        }

        case 'straps_slider': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                product: page.product.product[storeCode]
              }
            }
          } catch (error) {
            return {
              component: 'straps_slider_error',
            }
          }
        }

        case 'product_header': {
          try {
            const { sku, name, final_price, stock, image, description, is_shoppable, only_available_online, option_value_line, media_gallery, is_preorder, shipping_details, is_icon, option_value_collection } = page.product.product[storeCode]
            const { is_in_stock } = stock || { is_in_stock: false }
            return {
              component: slice.slice_type,
              item: {
                overrideCtaLabel: slice && slice.primary ? slice.primary.block_cta_override_label : null,
                overrideCtaLink: slice && slice.primary ? slice.primary.block_cta_override_link : '/',
                product: page.product.product[storeCode],
                productTitle: slice.primary.product_title,
                sku,
                name,
                collection: is_icon ? option_value_collection : option_value_line,
                description: description ? description.replace(/\|\|/g, '\n') : '',
                tag: slice.primary.block_tag,
                price: final_price,
                isInStock: is_in_stock,
                isShoppable: is_shoppable,
                isPreorder: is_preorder,
                shippingDetails: shipping_details,
                onlyAvailableOnline: only_available_online,
                soldat: {
                  src: ImageManager.convert(`${config.magento2.imgUrl}${image}`, 'image', 'product'),
                  alt: name + ' soldat picture'
                },
                images: media_gallery
                  .filter(({ typ, image: _image }) => typ === 'image' && _image !== image)
                  .sort((a, b) => a.pos > b.pos ? 1 : -1)
                  .map(({ image, lab }) => ({
                    src: ImageManager.convert(`${config.magento2.imgUrl}${image}`, 'image', 'product'),
                    alt: lab && lab.length ? lab : 'product_soldat'
                  }))
              }
            }
          } catch (error) {
            return {
              component: 'product_header_error'
            }
          }
        }


        case 'product_zoom': {
          try {
            return {
              component: slice.slice_type,
              items: {
                image: {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                }
              }
            }
          } catch (error) {
            return {
              component: 'product_zoom_error'
            }
          }
        }


        case 'sticky_product_bar': {
          try {
            const { sku, name, final_price, stock, is_shoppable, only_available_online, is_preorder } = page.product.product[storeCode]
            const { is_in_stock } = stock || { is_in_stock: false }
            return {
              component: slice.slice_type,
              item: {
                product: page.product.product[storeCode],
                sku,
                name,
                tag: slice.primary.block_tag,
                price: final_price,
                isInStock: is_in_stock,
                isShoppable: is_shoppable,
                isPreorder: is_preorder,
                onlyAvailableOnline: only_available_online
              }
            }
          } catch (error) {
            return {
              component: 'sticky_product_bar_error'
            }
          }
        }


        case 'technical_specification_hero': {
          try {
            const { option_value_diameter, frequency_raw, option_value_power_reserve, option_value_water_resistance } = page.product.product[storeCode]
            return {
              component: slice.slice_type,
              item: {
                diameter: option_value_diameter ? option_value_diameter.match(/[+-]?\d+(\.\d+)?/g).map(Number) : 0,
                frequency: frequency_raw,
                powerReserve: option_value_power_reserve ? option_value_power_reserve.match(/[+-]?\d+(\.\d+)?/g).map(Number) : 0,
                waterResistance: option_value_water_resistance ? option_value_water_resistance.match(/[+-]?\d+(\.\d+)?/g).map(Number).length > 1 ? option_value_water_resistance.match(/[+-]?\d+(\.\d+)?/g).map(Number)[0] : option_value_water_resistance.match(/[+-]?\d+(\.\d+)?/g).map(Number) : 0
              }
            }
          } catch (error) {
            return {
              component: 'technical_specification_hero_error'
            }
          }
        }

        case 'technical_specification': {
          try {
            const {
              option_value_movement,
              components,
              option_value_frequency,
              option_value_power_reserve,
              option_value_material,
              option_value_diameter,
              option_value_water_resistance,
              option_value_dial,
              option_value_bracelet,
              option_value_buckle,
              functions_1,
              functions_2,
              pdf,
              case_gemsettings,
              bezel_gemsettings,
              lugs_gemsettings,
              crown_gemsettings,
              dial_gemsettings,
              buckle_gemsettings,
              bracelet_gemsettings,
              second_bracelet,
              second_strap_included
            } = page.product.product[storeCode]
            return {
              component: slice.slice_type,
              item: {
                movement: {
                  movement: option_value_movement,
                  components: components,
                  frequency: option_value_frequency,
                  powerReserve: option_value_power_reserve
                },
                strap: {
                  strap: {
                    label: 'strap',
                    value: option_value_bracelet
                  },
                  ...(!!second_strap_included ? {
                    secondStrap: {
                      label: 'interchangable strap',
                      value: second_bracelet
                    }
                   } : {}),
                  clasp: {
                    label: 'clasp',
                    value: option_value_buckle
                  }
                },
                case: {
                  material: option_value_material,
                  diameter: option_value_diameter,
                  waterResistance: option_value_water_resistance,
                  dial: option_value_dial
                },
                gemsettings: {
                  case: case_gemsettings,
                  bezel: bezel_gemsettings,
                  lugs: lugs_gemsettings,
                  crown: crown_gemsettings,
                  dial: dial_gemsettings,
                  buckle: buckle_gemsettings,
                  bracelet: bracelet_gemsettings
                },
                pdf,
                function: [
                  (functions_1 || '').replace(/\|\|/g, '\n'),
                  (functions_2 || '').replace(/\|\|/g, '\n')
                ]
              }
            }
          } catch (error) {
            return {
              component: 'technical_specification_error'
            }
          }
        }

        case 'technical_specification_manual': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title
              },
              items: slice.items.map(item => ({
                title: item.block_title,
                text: item.block_text ? PrismicDOM.RichText.asHtml(item.block_text) : null
              }))
            }
          } catch (error) {
            return {
              component: 'technical_specification_manual_error'
            }
          }
        }


        case 'image_toggler': {
          try {
            return {
              component: slice.slice_type,
              item: {
                image: {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                },
                toggle: {
                  url: ImageManager.convert(slice.primary.block_image_toggle.url),
                  alt: slice.primary.block_image_toggle.alt,
                  width: slice.primary.block_image_toggle.dimensions.width,
                  height: slice.primary.block_image_toggle.dimensions.height,
                  filter: slice.primary.block_image_toggle_filter ? slice.primary.block_image_toggle_filter : ''
                },
              }
            }
          } catch (error) {
            return {
              component: 'image_toggler_error'
            }
          }
        }

        case 'contact_form': {
          try {
            return {
              component: slice.slice_type,
              item: {
                fullAddress: slice.primary.block_full_address == 'Yes',
                limitedCountryList: slice.primary.block_limited_country_list === 'true',
                shippingKit: slice.primary.block_shipping_kit === 'true',
                exampleImage: slice.primary.block_shipping_kit_image && slice.primary.block_shipping_kit_image.url ? {
                  url: ImageManager.convert(slice.primary.block_shipping_kit_image.url),
                  alt: slice.primary.block_shipping_kit_image.alt || ''
                } : null
              }
            }
          } catch (error) {
            return {
              component: 'contact_form_error'
            }
          }
        }

        case 'video_player': {
          try {
            return {
              component: slice.slice_type,
              item: {
                url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                alt: slice.primary.block_video.name,
                coverUrl: ImageManager.convert(slice.primary.block_video_cover.url),
                coverAlt: slice.primary.block_video_cover.alt,
                coverWidth: slice.primary.block_video_cover.dimensions.width,
                coverHeight: slice.primary.block_video_cover.dimensions.height,
                subtitle: {
                  label: storeView.i18n.defaultLanguage,
                  srclang: storeView.i18n.defaultLocale.split('-')[0],
                  src: slice.primary.block_video_subtitle ? ImageManager.convert(slice.primary.block_video_subtitle.url, 'video') : '',
                }
              }
            }
          } catch (error) {
            return {
              component: 'video_player_error'
            }
          }
        }
        case 'text_image_video': {
          try {
            const items: any = [
              {
                type: 'text',
                icon: slice.primary.block_icon,
                title: slice.primary.block_title,
                headline: slice.primary.block_headline,
                description: slice.primary.block_subtitle,
                text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                ctaText: slice.primary.block_text_cta_text,
                ctaLink: slice.primary.block_text_cta_link ? slice.primary.block_text_cta_link : '',
                ctaVideo: (slice.primary.block_text_cta_video && slice.primary.block_text_cta_video.url) ? ImageManager.convert(slice.primary.block_text_cta_video.url, 'video') : '',
                subtitle: {
                  label: storeView.i18n.defaultLanguage,
                  srclang: storeView.i18n.defaultLocale.split('-')[0],
                  src: slice.primary.block_text_cta_video_subtitle ? ImageManager.convert(slice.primary.block_text_cta_video_subtitle.url, 'video') : ''
                },
                position: slice.primary.block_text_position
              },
              {
                type: 'image',
                url: ImageManager.convert(slice.primary.block_image.url),
                alt: slice.primary.block_image.alt,
                width: slice.primary.block_image.dimensions.width,
                height: slice.primary.block_image.dimensions.height,
                filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : '',
                ctaText: slice.primary.block_image_cta_text,
                ctaLink: slice.primary.block_image_cta_link ? slice.primary.block_image_cta_link : '',
                position: slice.primary.block_image_position,
              }
            ]

            if (slice.primary.block_video_cover.url) {
              items.push(
                {
                  type: 'video',
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name,
                  coverUrl: ImageManager.convert(slice.primary.block_video_cover.url),
                  coverAlt: slice.primary.block_video_cover.alt,
                  coverWidth: slice.primary.block_video_cover.dimensions ? slice.primary.block_video_cover.dimensions.width : 0,
                  coverHeight: slice.primary.block_video_cover.dimensions ? slice.primary.block_video_cover.dimensions.height : 0,
                  coverFilter: slice.primary.block_image_filter ? slice.primary.block_image_filter : '',
                  ctaText: slice.primary.block_video_cta_text,
                  subtitle: {
                    label: storeView.i18n.defaultLanguage,
                    srclang: storeView.i18n.defaultLocale.split('-')[0],
                    src: slice.primary.block_video_subtitle ? ImageManager.convert(slice.primary.block_video_subtitle.url, 'video') : ''
                  },
                  position: slice.primary.block_video_position
                }
              )
            }
            return {
              component: 'three_columns',
              items: items.sort((a, b) => a.position > b.position ? 1 : -1)
            }
          } catch (error) {
            return {
              component: 'text_image_video_error'
            }
          }
        }

        case 'text_image_image': {
          try {
            return {
              component: 'three_columns',
              items: [
                {
                  type: 'text',
                  icon: slice.primary.block_icon,
                  headline: slice.primary.block_headline,
                  title: slice.primary.block_title,
                  subtitle: slice.primary.block_subtitle,
                  text: slice.primary.block_text ? PrismicDOM.RichText.asHtml(slice.primary.block_text) : null,
                  ctaText: slice.primary.block_text_cta_text,
                  ctaLink: slice.primary.block_text_cta_link ? slice.primary.block_text_cta_link : '',
                  position: slice.primary.block_text_position
                },
                {
                  type: 'image',
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : '',
                  ctaText: slice.primary.block_image_cta_text,
                  ctaLink: slice.primary.block_image_cta_link ? slice.primary.block_image_cta_link : '',
                  position: slice.primary.block_image_position
                },
                {
                  type: 'image',
                  url: ImageManager.convert(slice.primary.block_second_image.url),
                  alt: slice.primary.block_second_image.alt,
                  width: slice.primary.block_second_image.dimensions.width,
                  height: slice.primary.block_second_image.dimensions.height,
                  filter: slice.primary.block_second_image_filter ? slice.primary.block_second_image_filter : '',
                  ctaText: slice.primary.block_second_image_cta_text,
                  ctaLink: slice.primary.block_second_image_cta_link ? slice.primary.block_second_image_cta_link : '',
                  position: slice.primary.block_second_image_position
                }
              ].sort((a, b) => a.position > b.position ? 1 : -1)
            }
          } catch (error) {
            return {
              component: 'text_image_image_error'
            }
          }
        }

        case 'video_video_video': {
          try {
            return {
              component: 'three_columns',
              items: [
                {
                  type: 'video',
                  url: ImageManager.convert(slice.primary.block_video_1.url, 'video'),
                  alt: slice.primary.block_video_1.name,
                  coverUrl: slice.primary.block_video_1_cover && slice.primary.block_video_1_cover.url ? ImageManager.convert(slice.primary.block_video_1_cover.url) : '',
                  coverAlt: slice.primary.block_video_1_cover && slice.primary.block_video_1_cover.alt ? slice.primary.block_video_1_cover.alt : '',
                  ctaText: slice.primary.block_video_1_cta_text,
                  subtitle: {
                    label: storeView.i18n.defaultLanguage,
                    srclang: storeView.i18n.defaultLocale.split('-')[0],
                    src: slice.primary.block_video_1_subtitle && slice.primary.block_video_1_subtitle.url ? ImageManager.convert(slice.primary.block_video_1_subtitle.url, 'video') : ''
                  },
                  position: slice.primary.block_video_1_position
                },
                {
                  type: 'video',
                  url: ImageManager.convert(slice.primary.block_video_2.url, 'video'),
                  alt: slice.primary.block_video_2.name,
                  coverUrl: slice.primary.block_video_2_cover && slice.primary.block_video_2_cover.url ? ImageManager.convert(slice.primary.block_video_2_cover.url) : '',
                  coverAlt: slice.primary.block_video_2_cover && slice.primary.block_video_2_cover.alt ? slice.primary.block_video_2_cover.alt : '',
                  ctaText: slice.primary.block_video_2_cta_text,
                  subtitle: {
                    label: storeView.i18n.defaultLanguage,
                    srclang: storeView.i18n.defaultLocale.split('-')[0],
                    src: slice.primary.block_video_2_subtitle && slice.primary.block_video_2_subtitle.url ? ImageManager.convert(slice.primary.block_video_2_subtitle.url, 'video') : ''
                  },
                  position: slice.primary.block_video_2_position
                },
                {
                  type: 'video',
                  url: ImageManager.convert(slice.primary.block_video_3.url, 'video'),
                  alt: slice.primary.block_video_3.name,
                  coverUrl: slice.primary.block_video_3_cover && slice.primary.block_video_3_cover.url ? ImageManager.convert(slice.primary.block_video_3_cover.url) : '',
                  coverAlt: slice.primary.block_video_3_cover && slice.primary.block_video_3_cover.alt ? slice.primary.block_video_3_cover.alt : '',
                  ctaText: slice.primary.block_video_3_cta_text,
                  subtitle: {
                    label: storeView.i18n.defaultLanguage,
                    srclang: storeView.i18n.defaultLocale.split('-')[0],
                    src: slice.primary.block_video_3_subtitle && slice.primary.block_video_3_subtitle.url ? ImageManager.convert(slice.primary.block_video_3_subtitle.url, 'video') : ''
                  },
                  position: slice.primary.block_video_3_position
                }
              ].sort((a, b) => a.position > b.position ? 1 : -1)
            }
          } catch (error) {
            return {
              component: 'video_video_video_error',
              slice
            }
          }
        }

        case 'watch_collection': {
          try {
            return {
              component: 'watch_collection',
              item: {
                collection: slice.primary.block_collection,
                line: slice.primary.block_line
              }
            }
          } catch (error) {
            return {
              component: 'watch_collection_error'
            }
          }
        }
        case 'download': {
          try {
            return {
              component: 'download',
              // components: slice.primary.download.data.body.map(component => ({
              //   component: component.slice_type,
              //   items: component.items
              // }))
              components: slice.primary.download.data.body.map(component => {
                return subComponentProcessor(component, slice)
              })
            }
          } catch (error) {
            return {
              component: 'download_error'
            }
          }
        }
        case 'custom_form': {
          try {
            return {
              component: 'custom_form',
              item: {
                formId: slice.primary.prismic_custom_form,
                fullAddress: slice.primary.full_form === 'true',
                sendToSF: slice.primary.send_to_sf === 'true',
                phoneRequired: slice.primary.phone_required === 'true',
              }
            }
          } catch (error) {
            return {
              component: 'custom_form_error'
            }
          }
        }


        case 'countdown_banner': {
          try {
            return {
              component: 'countdown_banner',
              item: {
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                type: slice.primary.block_video && slice.primary.block_video.url ? 'video' : 'image',
                date: slice.primary.block_date,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link,
              }
            }
          } catch (error) {
            return {
              component: 'countdown_banner_error'
            }
          }
        }

        case 'defy_extreme_editorial': {
          try {
            return {
              component: 'defy_extreme_editorial',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
              },
              items: slice.items.map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  products: [
                    item.block_product_a && item.block_product_a.data ? { routeParam: item.block_product_a.data.uid, ...item.block_product_a.data.product[storeCode] } : null,
                    item.block_product_b && item.block_product_b.data ? { routeParam: item.block_product_b.data.uid, ...item.block_product_b.data.product[storeCode] } : null,
                    item.block_product_c && item.block_product_c.data ? { routeParam: item.block_product_c.data.uid, ...item.block_product_c.data.product[storeCode] } : null,
                    item.block_product_d && item.block_product_d.data ? { routeParam: item.block_product_d.data.uid, ...item.block_product_d.data.product[storeCode] } : null,
                  ].filter(x => x)
                }
              })
            }
          } catch (error) {
            return {
              component: 'defy_extreme_editorial_error'
            }
          }
        }
        case 'defy_extreme_fullscreen_video': {
          try {
            return {
              component: 'defy_extreme_fullscreen_video',
              item: {
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                cover: slice.primary.block_video_cover && slice.primary.block_video_cover.url ? {
                  url: ImageManager.convert(slice.primary.block_video_cover.url),
                  alt: slice.primary.block_video_cover.alt,
                  width: slice.primary.block_video_cover.dimensions.width,
                  height: slice.primary.block_video_cover.dimensions.height,
                } : null,
                coverMobile: slice.primary.image_mobile && slice.primary.image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.image_mobile.url),
                  alt: slice.primary.image_mobile.alt,
                  width: slice.primary.image_mobile.dimensions.width,
                  height: slice.primary.image_mobile.dimensions.height,
                } : null,
                subtitle: slice.primary.block_video_subtitle && slice.primary.block_video_subtitle.url ? {
                  label: storeView.i18n.defaultLanguage,
                  srclang: storeView.i18n.defaultLocale.split('-')[0],
                  src: ImageManager.convert(slice.primary.block_video_subtitle.url, 'video'),
                } : slice.primary.block_subtitles && slice.primary.block_subtitles.url ? {
                  label: storeView.i18n.defaultLanguage,
                  srclang: storeView.i18n.defaultLocale.split('-')[0],
                  src: ImageManager.convert(slice.primary.block_subtitles.url, 'video'),
                } : null,
                impolicy: slice.primary.impolicy
              },
              // items: []
            }
          } catch (error) {
            return {
              component: 'defy_extreme_fullscreen_video_error'
            }
          }
        }


        case 'defy_extreme_sticky_header': {
          try {
            return {
              component: 'defy_extreme_sticky_header',
              item: {
                title: slice.primary.block_title,
                product: slice.primary.block_product && slice.primary.block_product.data ? { routeParam: slice.primary.block_product.uid, ...slice.primary.block_product.data.product[storeCode] } : null,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                declination: slice.primary.block_declination === 'full' ? 'full' : 'container'
              },
            }
          } catch (error) {
            return {
              component: 'defy_extreme_sticky_header_error'
            }
          }
        }
        case 'defy_extreme_header': {
          try {
            return {
              component: 'defy_extreme_header',
              item: {
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                mobileVideo: slice.primary.block_video_mobile && slice.primary.block_video_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_video_mobile.url, 'video'),
                  alt: slice.primary.block_video_mobile.name
                } : null,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                } : null,
                headerTitle: slice.primary.header_title,
                headline: slice.primary.block_headline,
                titleA: slice.primary.block_title_a,
                titleB: slice.primary.block_title_b,
              },
              // items: []
            }
          } catch (error) {
            return {
              component: 'defy_extreme_header_error'
            }
          }
        }
        case 'defy_extreme_horizontal_slider': {
          try {
            return {
              component: 'defy_extreme_horizontal_slider',
              item: {
                titleA: slice.primary.block_title_a,
                titleB: slice.primary.block_title_b,
                titleC: slice.primary.block_title_c,
                textA: slice.primary.block_text_a,
                textB: slice.primary.block_text_b,
                textC: slice.primary.block_text_c,
                tutorialText: slice.primary.block_tutorial_text,
              },
              items: slice.items.map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  products: [
                    item.block_product_a && item.block_product_a.data ? { routeParam: item.block_product_a.data.uid, ...item.block_product_a.data.product[storeCode] } : null,
                    item.block_product_b && item.block_product_b.data ? { routeParam: item.block_product_b.data.uid, ...item.block_product_b.data.product[storeCode] } : null,
                    item.block_product_c && item.block_product_c.data ? { routeParam: item.block_product_c.data.uid, ...item.block_product_c.data.product[storeCode] } : null,
                    item.block_product_d && item.block_product_d.data ? { routeParam: item.block_product_d.data.uid, ...item.block_product_d.data.product[storeCode] } : null,
                  ].filter(x => x),
                  show: item.block_show === 'show'
                }
              })
            }
          } catch (error) {
            return {
              component: 'defy_extreme_horizontal_slider_error'
            }
          }
        }
        case 'defy_extreme_media_text': {
          try {
            return {
              component: 'defy_extreme_media_text',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height
                } : null,
                side: slice.primary.block_side,
                ctaLabel: slice.primary.block_cta_label,
                ctaLink: slice.primary.block_cta_link,
              },
              // items: []
            }
          } catch (error) {
            return {
              component: 'defy_extreme_media_text_error'
            }
          }
        }
        case 'defy_extreme_movement': {
          try {
            return {
              component: 'defy_extreme_movement',
              item: {
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
              },
              // items: []
            }
          } catch (error) {
            return {
              component: 'defy_extreme_movement_error'
            }
          }
        }
        case 'defy_extreme_product_slider': {
          try {
            return {
              component: 'defy_extreme_product_slider',
              // item: {},
              items: slice.items.map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  product: item.block_product && item.block_product.data ? { routeParam: item.block_product.data.uid, ...item.block_product.data.product[storeCode] } : null,
                }
              })
            }
          } catch (error) {
            return {
              component: 'defy_extreme_product_slider_error'
            }
          }
        }
        case 'video_streaming': {
          try {
            return {
              component: 'video_streaming',
              item: {
                title: slice.primary.video_title ? PrismicDOM.RichText.asText(slice.primary.video_title) : null,
                description: slice.primary.video_subtitle ? slice.primary.video_subtitle : null,
                button_label: slice.primary.video_button_label ? slice.primary.video_button_label : null,
                video_id: slice.primary.video_public_id ? slice.primary.video_public_id : null,
                subtitles: slice.primary.video_subtitles && slice.primary.video_subtitles.url ? {
                  label: storeView.i18n.defaultLanguage,
                  srclang: storeView.i18n.defaultLocale.split('-')[0],
                  src: slice.primary.video_subtitles ? ImageManager.convert(slice.primary.video_subtitles.url, 'video') : ''
                } : null,
                controls: slice.primary.video_show_controls,
                streaming: slice.primary.video_use_streaming,
                fullscreen: slice.primary.video_format,
                autoplay: slice.primary.video_autoplay,
                black_background: slice.primary.video_black_background,
                muted: slice.primary.video_muted,
                poster: slice.primary.video_poster && slice.primary.video_poster.url ? {
                  url: ImageManager.convert(slice.primary.video_poster.url),
                  alt: slice.primary.video_poster.alt,
                  width: slice.primary.video_poster.dimensions.width,
                  height: slice.primary.video_poster.dimensions.height,
                  filter: slice.primary.video_poster_filter ? slice.primary.video_poster_filter : ''
                } : null,
                start_on: slice.primary.video_start_time,
                caption: slice.primary.video_optional_text
              }
            }
          } catch (error) {
            return {
              component: 'video_streaming'
            }
          }
        }
        case 'hero_image': {
          try {
            return {
              component: 'hero_image',
              item: {
                headline: slice.primary.hero_headline ? slice.primary.hero_headline : null,
                title: slice.primary.hero_title ? PrismicDOM.RichText.asText(slice.primary.hero_title) : null,
                subtitle: slice.primary.hero_subtitle ? PrismicDOM.RichText.asText(slice.primary.hero_subtitle) : null,
                description: slice.primary.video_subtitle ? slice.primary.video_subtitle : null,
                cta_label: slice.primary.cta_label ? slice.primary.cta_label : null,
                cta_link: slice.primary.cta_link ? slice.primary.cta_link : null,
                cta_text_color: slice.primary.cta_text_color,
                cta_text_color_mobile: slice.primary.cta_text_color_mobile,
                subtitle_text_color: slice.primary.subtitle_text_color,
                subtitle_text_color_mobile: slice.primary.subtitle_text_color_mobile,
                title_text_color: slice.primary.title_text_color,
                title_text_color_mobile: slice.primary.title_text_color_mobile,
                headline_text_color: slice.primary.headline_text_color,
                headline_text_color_mobile: slice.primary.headline_text_color_mobile,
                main_picture: slice.primary.main_picture && slice.primary.main_picture.url ? {
                  url: ImageManager.convert(slice.primary.main_picture.url),
                  alt: slice.primary.main_picture.alt,
                  width: slice.primary.main_picture.dimensions.width,
                  height: slice.primary.main_picture.dimensions.height,
                  filter: ''
                } : null,
                mobile_picture: slice.primary.mobile_picture && slice.primary.mobile_picture.url ? {
                  url: ImageManager.convert(slice.primary.mobile_picture.url),
                  alt: slice.primary.mobile_picture.alt,
                  width: slice.primary.mobile_picture.dimensions.width,
                  height: slice.primary.mobile_picture.dimensions.height,
                  filter: ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url && slice.primary.block_video.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name,
                } : null,               
                videoMobile: slice.primary.block_video_mobile && slice.primary.block_video_mobile.url && slice.primary.block_video_mobile.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video_mobile.url, 'video'),
                  alt: slice.primary.block_video_mobile.name,
                } : null,
                impolicy: slice.primary.impolicy,
                use_overlay: slice.primary.hero_use_overlay,
                theme: slice.primary.hero_black_theme ? 'dark' : 'default',
                trackingLight: slice.primary.tracking_light
              }
            }
          } catch (error) {
            return {
              component: 'hero_image'
            }
          }
        }
        case 'two_images_hero_banner': {
          const getContent = (suffix: string) => {
            return {
              headline: slice.primary[`headline_${suffix}`] || null,
              title: slice.primary[`title_${suffix}`] || null,
              cta_label: slice.primary[`cta_label_${suffix}`] || null,
              cta_link: slice.primary[`cta_link_${suffix}`] || null,
              image: slice.primary[`image_${suffix}`] && slice.primary[`image_${suffix}`].url ? {
                url: ImageManager.convert(slice.primary[`image_${suffix}`].url),
                alt: slice.primary[`image_${suffix}`].alt,
                width: slice.primary[`image_${suffix}`].dimensions.width,
                height: slice.primary[`image_${suffix}`].dimensions.height,
                filter: ''
              } : null,
              imageMobile: slice.primary[`mobile_image_${suffix}`] && slice.primary[`mobile_image_${suffix}`].url ? {
                url: ImageManager.convert(slice.primary[`mobile_image_${suffix}`].url),
                alt: slice.primary[`mobile_image_${suffix}`].alt,
                width: slice.primary[`mobile_image_${suffix}`].dimensions.width,
                height: slice.primary[`mobile_image_${suffix}`].dimensions.height,
                filter: ''
              } : null
            }
          }
          try {
            return {
              component: 'two_images_hero_banner',
              item: {
                left_content: getContent('1'),
                right_content: getContent('2'),
                text_color: slice.primary.text_color,
                text_color_mobile: slice.primary.mobile_text_color
              }
            }
          } catch (error) {
            console.log('error', error)
            return {
              component: 'two_images_hero_banner'
            }
          }
        }
        case 'hero_countdown': {
          try {
            return {
              component: 'hero_countdown',
              item: {
                headline: slice.primary.hero_headline ? slice.primary.hero_headline : null,
                title: slice.primary.hero_title ? PrismicDOM.RichText.asText(slice.primary.hero_title) : null,
                subtitle: slice.primary.hero_subtitle ? PrismicDOM.RichText.asText(slice.primary.hero_subtitle) : null,
                description: slice.primary.video_subtitle ? slice.primary.video_subtitle : null,
                cta_label: slice.primary.cta_label ? slice.primary.cta_label : null,
                cta_link: slice.primary.cta_link ? slice.primary.cta_link : null,
                cta_text_color: slice.primary.cta_text_color,
                cta_text_color_mobile: slice.primary.cta_text_color_mobile,
                title_text_color: slice.primary.title_text_color,
                title_text_color_mobile: slice.primary.title_text_color_mobile,
                subtitle_text_color: slice.primary.subtitle_text_color,
                subtitle_text_color_mobile: slice.primary.subtitle_text_color_mobile,
                headline_text_color: slice.primary.headline_text_color,
                headline_text_color_mobile: slice.primary.headline_text_color_mobile,
                main_picture: slice.primary.main_picture && slice.primary.main_picture.url ? {
                  url: ImageManager.convert(slice.primary.main_picture.url),
                  alt: slice.primary.main_picture.alt,
                  width: slice.primary.main_picture.dimensions.width,
                  height: slice.primary.main_picture.dimensions.height,
                  filter: ''
                } : null,
                mobile_picture: slice.primary.mobile_picture && slice.primary.mobile_picture.url ? {
                  url: ImageManager.convert(slice.primary.mobile_picture.url),
                  alt: slice.primary.mobile_picture.alt,
                  width: slice.primary.mobile_picture.dimensions.width,
                  height: slice.primary.mobile_picture.dimensions.height,
                  filter: ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url && slice.primary.block_video.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name,
                } : null,
                videoMobile: slice.primary.block_video_mobile && slice.primary.block_video_mobile.url && slice.primary.block_video_mobile.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video_mobile.url, 'video'),
                  alt: slice.primary.block_video_mobile.name,
                } : null,
                impolicy: slice.primary.impolicy,
                use_overlay: slice.primary.hero_use_overlay,
                theme: slice.primary.hero_black_theme ? 'dark' : 'default',
                trackingLight: slice.primary.tracking_light,
                countDown: slice.primary.countdown,
                countDownTitle: slice.primary.countdown_title
              }
            }
          } catch (error) {
            return {
              component: 'hero_countdown'
            }
          }
        }

        case 'hr_cta_title_text': {
          try {
            return {
              component: 'hr_cta_title_text',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link,
              }
            }
          } catch (error) {
            return {
              component: 'hr_cta_title_text_error'
            }
          }
        }
        case 'hr_dreamhers': {
          try {
            return {
              component: 'hr_dreamhers',
              items: slice.items.map(item => {
                return {
                  type: item.block_image && item.block_image.url ? 'image' : 'video',
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height,
                  } : null,
                  video: item.block_video && item.block_video.url ? {
                    url: ImageManager.convert(item.block_video.url, 'video'),
                    alt: item.block_video.name
                  } : null,
                  title: item.block_title,
                  text: item.block_text,
                  ctaText: item.block_cta_text,
                  ctaLink: item.block_cta_link ? item.block_cta_link : '',
                  position: item.block_position,
                  url: item.block_url
                }
              })
            }
          } catch (error) {
            return {
              component: 'hr_dreamhers_error'
            }
          }
        }

        case 'hr_dual_tile': {
          try {
            return {
              component: 'hr_dual_tile',
              item: {
                animated: slice.primary.block_animated
              },
              items: slice.items.map(item => {
                return {
                  title: item.block_title,
                  headline: item.block_headline,
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height,
                  } : null,
                  ctaText: item.block_cta_text,
                  ctaLink: item.block_cta_link ? item.block_cta_link : '',
                  textColor: item.block_text_color
                }
              })
            }
          } catch (error) {
            return {
              component: 'hr_dual_tile_error'
            }
          }
        }

        case 'hr_full_width_tile': {
          try {
            return {
              component: 'hr_full_width_tile',
              item: {
                animated: slice.primary.block_animated,
                title: slice.primary.block_title,
                headline: slice.primary.block_headline,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                // imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                //   url: ImageManager.convert(slice.primary.block_image_mobile.url),
                //   alt: slice.primary.block_image_mobile.alt,
                //   width: slice.primary.block_image_mobile.dimensions.width,
                //   height: slice.primary.block_image_mobile.dimensions.height,
                // } : null,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link ? slice.primary.block_cta_link : '',
                textColor: slice.primary.block_text_color
              }
            }
          } catch (error) {
            return {
              component: 'hr_full_width_tile_error'
            }
          }
        }

        case 'hr_hero_banner': {
          try {
            return {
              component: 'hr_hero_banner',
              item: {
                title: slice.primary.block_title,
                headline: slice.primary.block_headline,
                text: slice.primary.block_text,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                } : null,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link ? slice.primary.block_cta_link : '',
                textColor: slice.primary.block_text_color
              },
              items: [

              ]
            }
          } catch (error) {
            return {
              component: 'hr_hero_banner_error'
            }
          }
        }

        // case 'hr_icon_title_text': {
        //   try {
        //     return {
        //       component: 'hr_icon_title_text',
        //       item: {
        //       },
        //       items: [
        //         {
        //           title: slice.primary.block_title,
        //           headline: slice.primary.block_headline,
        //           text: slice.primary.block_text,
        //         }
        //       ]
        //     }
        //   } catch (error) {
        //     return {
        //       component: 'hr_icon_title_text_error'
        //     }
        //   }
        // }

        case 'hr_new_products': {
          try {
            return {
              component: 'hr_new_products',
            }
          } catch (error) {
            return {
              component: 'hr_new_products_error'
            }
          }
        }

        case 'pdpr_image_title_text': {
          try {
            return {
              component: 'pdpr_image_title_text',
              item: {
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                imageA: slice.primary.block_image_a && slice.primary.block_image_a.url ? {
                  url: ImageManager.convert(slice.primary.block_image_a.url),
                  alt: slice.primary.block_image_a.alt,
                  width: slice.primary.block_image_a.dimensions.width,
                  height: slice.primary.block_image_a.dimensions.height,
                } : null,
                imageMobileA: slice.primary.block_image_mobile_a && slice.primary.block_image_mobile_a.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile_a.url),
                  alt: slice.primary.block_image_mobile_a.alt,
                  width: slice.primary.block_image_mobile_a.dimensions.width,
                  height: slice.primary.block_image_mobile_a.dimensions.height,
                } : null,
                imageB: slice.primary.block_image_b && slice.primary.block_image_b.url ? {
                  url: ImageManager.convert(slice.primary.block_image_b.url),
                  alt: slice.primary.block_image_b.alt,
                  width: slice.primary.block_image_b.dimensions.width,
                  height: slice.primary.block_image_b.dimensions.height,
                } : null,
                imageMobileB: slice.primary.block_image_mobile_b && slice.primary.block_image_mobile_b.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile_b.url),
                  alt: slice.primary.block_image_mobile_b.alt,
                  width: slice.primary.block_image_mobile_b.dimensions.width,
                  height: slice.primary.block_image_mobile_b.dimensions.height,
                } : null,
              }
            }
          } catch (error) {
            return {
              component: 'pdpr_image_title_text_error'
            }
          }
        }

        case 'pdpr_image_title_text_icon': {
          try {
            const extractImageTitleImage = (key: string) => {
              if (!slice.primary[key] || !slice.primary[key].url) {
                return null
              }

              const {url, alt, dimensions: {width, height}} = slice.primary[key]

              return {
                url: ImageManager.convert(url),
                alt: alt,
                width: width,
                height: height
              }
            }
            return {
              component: 'pdpr_image_title_text_icon',
              item: {
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                icon: slice.primary.block_icon || '',
                global: slice,
                images: [{
                  image: extractImageTitleImage('block_image'),
                  imageMobile: extractImageTitleImage('block_image_mobile')
                }, {
                  image: extractImageTitleImage('block_second_image'),
                  imageMobile: extractImageTitleImage('block_second_image_mobile')
                }]
              }
            }
          } catch (error) {
            console.error('error', error)
            return {
              component: 'pdpr_image_title_text_icon_error'
            }
          }
        }

        case 'pdpr_services_collapse': {
          try {
            return {
              component: 'pdpr_services_collapse',
              item: {
              },
              items: slice.items.map(item => {
                return {
                  title: item.block_title,
                  text: item.block_text,
                  icon: item.block_icon,
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                }
              })
            }
          } catch (error) {
            return {
              component: 'pdpr_services_collapse_error'
            }
          }
        }

        case 'defy_5hz_hero': {
          try {
            const images = []
            const suffix = ['b', 'c', 'd', 'e', 'f', 'g']

            const buildImageProperties = (image, key) => {
              return {
                url: ImageManager.convert(image.url),
                alt: image.alt,
                width: image.dimensions.width,
                height: image.dimensions.height,
                key: key
              }
            }

            for (const block of suffix) {
              const image = {};
              const key = `block_image_${block}`
              const mobileKey = `block_image_mobile_${block}`
              let shouldAdd = false

              if (slice.primary.hasOwnProperty(key) && !!slice.primary[key].url) {
                image['image'] = buildImageProperties(slice.primary[key], key)
                shouldAdd = true
              }

              if (slice.primary.hasOwnProperty(mobileKey) && !!slice.primary[mobileKey].url) {
                image['imageMobile'] = buildImageProperties(slice.primary[mobileKey], mobileKey)
                shouldAdd = true
              }

              if (shouldAdd) {
                images.push(image)
              }
            }

            return {
              component: 'defy_5hz_hero',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                subtitle: slice.primary.block_subtitle,
                image: slice.primary.block_image_a && slice.primary.block_image_a.url ? {
                  url: ImageManager.convert(slice.primary.block_image_a.url),
                  alt: slice.primary.block_image_a.alt,
                  width: slice.primary.block_image_a.dimensions.width,
                  height: slice.primary.block_image_a.dimensions.height
                } : null,
                imageMobile: slice.primary.block_image_mobile_a && slice.primary.block_image_mobile_a.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile_a.url),
                  alt: slice.primary.block_image_mobile_a.alt,
                  width: slice.primary.block_image_mobile_a.dimensions.width,
                  height: slice.primary.block_image_mobile_a.dimensions.height
                } : null,
                images: images
              }
            }
          } catch (error) {
            return {
              component: 'defy_5hz_hero_error'
            }
          }
        }

        case 'defy_5hz_video_text': {
          try {
            return {
              component: 'defy_5hz_video_text',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                video: slice.primary.block_video && slice.primary.block_video.url && slice.primary.block_video.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name,
                } : null,
                subtitle: {
                  label: storeView.i18n.defaultLanguage,
                  srclang: storeView.i18n.defaultLocale.split('-')[0],
                  src: slice.primary.block_video_subtitle ? ImageManager.convert(slice.primary.block_video_subtitle.url, 'video') : null,
                },
                image: slice.primary.block_video_cover && slice.primary.block_video_cover.url ? {
                  url: ImageManager.convert(slice.primary.block_video_cover.url),
                  alt: slice.primary.block_video_cover.alt,
                  width: slice.primary.block_video_cover.dimensions.width,
                  height: slice.primary.block_video_cover.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_video_cover_mobile && slice.primary.block_video_cover_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_video_cover_mobile.url),
                  alt: slice.primary.block_video_cover_mobile.alt,
                  width: slice.primary.block_video_cover_mobile.dimensions.width,
                  height: slice.primary.block_video_cover_mobile.dimensions.height,
                } : null,
              }
            }
          } catch (error) {
            return {
              component: 'defy_5hz_video_text_error'
            }
          }
        }

        case 'defy_5hz_dual_product': {
          try {
            return {
              component: 'defy_5hz_dual_product',
              item: {
                headline: slice.primary.block_headline
              },
              items: slice.items.map(item => {
                return {
                  image: item.block_image_a && item.block_image_a.url ? {
                    url: ImageManager.convert(item.block_image_a.url),
                    alt: item.block_image_a.alt,
                    width: item.block_image_a.dimensions.width,
                    height: item.block_image_a.dimensions.height,
                  } : null,
                  imageMobile: item.block_image_mobile_a && item.block_image_mobile_a.url ? {
                    url: ImageManager.convert(item.block_image_mobile_a.url),
                    alt: item.block_image_mobile_a.alt,
                    width: item.block_image_mobile_a.dimensions.width,
                    height: item.block_image_mobile_a.dimensions.height,
                  } : null,
                  products: [
                    item.block_product_a && item.block_product_a.data ? { routeParam: item.block_product_a.data.uid, ...item.block_product_a.data.product[storeCode] } : null,
                    item.block_product_b && item.block_product_b.data ? { routeParam: item.block_product_b.data.uid, ...item.block_product_b.data.product[storeCode] } : null,
                    item.block_product_c && item.block_product_c.data ? { routeParam: item.block_product_c.data.uid, ...item.block_product_c.data.product[storeCode] } : null,
                    item.block_product_d && item.block_product_d.data ? { routeParam: item.block_product_d.data.uid, ...item.block_product_d.data.product[storeCode] } : null,
                  ].filter(x => x)
                }
              })
            }
          } catch (error) {
            return {
              component: 'defy_5hz_dual_product_error'
            }
          }
        }

        case 'defy_5hz_image_text': {
          try {
            return {
              component: 'defy_5hz_image_text',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                } : null,
              }
            }
          } catch (error) {
            return {
              component: 'defy_5hz_image_text_error'
            }
          }
        }

        case 'defy_5hz_gif': {
          try {
            return {
              component: 'defy_5hz_gif',
              item: {
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                } : null,
              },
              items: slice.items.map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height,
                  } : null,
                  side: item.block_image_side
                }
              })
            }
          } catch (error) {
            return {
              component: 'defy_5hz_gif_error'
            }
          }
        }

        case 'basic_countdown': {
          try {
            return {
              component: 'basic_countdown',
              item: {
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                  filter: slice.primary.block_image_mobile_filter ? slice.primary.block_image_mobile_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                type: slice.primary.block_video && slice.primary.block_video.url ? 'video' : 'image',
                date: slice.primary.block_date,
              }
            }
          } catch (error) {
            return {
              component: 'basic_countdown_error'
            }
          }
        }

        case 'podcast': {
          try {
            return {
              component: 'podcast_' + slice.primary.declination,
              declination: slice.primary.declination, // "expertise" existing so far
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                  filter: slice.primary.block_image_mobile_filter ? slice.primary.block_image_mobile_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                subtitle: slice.primary.block_subtitle,
                type: slice.primary.block_video && slice.primary.block_video.url ? 'video' : 'image',
                side: slice.primary.side,
              },
              items: slice.items.map((item) => {
                return {
                  displayTitle: item.display_title,
                  displayFeaturing: item.display_featuring,
                  displayDescription: item.display_description,
                  episodeTitle: item.episode_title,
                  podcastTitle: item.podcast_title,
                  featuring: item.episode_featuring,
                  episodeDescription: item.episode_description,
                  image: item.episode_image && item.episode_image.url ? {
                    url: ImageManager.convert(item.episode_image.url),
                    alt: item.episode_image.alt,
                    width: item.episode_image.dimensions.width,
                    height: item.episode_image.dimensions.height,
                    filter: item.block_image_filter ? item.block_image_filter : ''
                  } : null,
                  imageMobile: item.episode_image_mobile && item.episode_image_mobile.url ? {
                    url: ImageManager.convert(item.episode_image_mobile.url),
                    alt: item.episode_image_mobile.alt,
                    width: item.episode_image_mobile.dimensions.width,
                    height: item.episode_image_mobile.dimensions.height,
                    filter: item.block_image_mobile_filter ? item.block_image_mobile_filter : ''
                  } : null,
                }
              })
            }
          } catch (error) {
            return {
              component: 'podcast_error'
            }
          }
        }


        case 'movement_selector': {
          try {
            return {
              component: 'movement_selector',
              declination: slice.primary.declination, // "expertise" existing so far
              item: {
                side: slice.primary.block_side,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                ctaText: slice.primary.block_cta_text,
                ctaLink: slice.primary.block_cta_link,
                additionalCtaText: slice.primary.block_additional_cta__text,
                additionnalCtaLink: slice.primary.block_additional_cta_link,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                  filter: slice.primary.block_image_mobile_filter ? slice.primary.block_image_mobile_filter : ''
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                subtitle: slice.primary.block_subtitle,
                type: slice.primary.block_video && slice.primary.block_video.url ? 'video' : 'image',
              },
              items: slice.items.map((item) => {
                return {
                  title: item.block_title,
                  text: item.block_text,
                  ctaText: item.block_cta_text,
                  ctaLink: item.block_cta_link,
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                    filter: item.block_image_filter ? slice.primary.block_image_filter : ''
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height,
                    filter: item.block_image_mobile_filter ? item.block_image_mobile_filter : ''
                  } : null,
                  products: [
                    item.block_product_a && item.block_product_a.data ? { routeParam: item.block_product_a.data.uid, ...item.block_product_a.data.product[storeCode], imageHover: (item.block_product_a.data.product[storeCode].secondary_image) ? ImageManager.convert(`${config.magento2.imgUrl}${item.block_product_a.data.product[storeCode].secondary_image}`, 'image', 'product') : null } : null,
                    item.block_product_b && item.block_product_b.data ? { routeParam: item.block_product_b.data.uid, ...item.block_product_b.data.product[storeCode], imageHover: (item.block_product_b.data.product[storeCode].secondary_image) ? ImageManager.convert(`${config.magento2.imgUrl}${item.block_product_b.data.product[storeCode].secondary_image}`, 'image', 'product') : null } : null,
                    item.block_product_c && item.block_product_c.data ? { routeParam: item.block_product_c.data.uid, ...item.block_product_c.data.product[storeCode], imageHover: (item.block_product_c.data.product[storeCode].secondary_image) ? ImageManager.convert(`${config.magento2.imgUrl}${item.block_product_c.data.product[storeCode].secondary_image}`, 'image', 'product') : null } : null,
                    item.block_product_d && item.block_product_d.data ? { routeParam: item.block_product_d.data.uid, ...item.block_product_d.data.product[storeCode], imageHover: (item.block_product_d.data.product[storeCode].secondary_image) ? ImageManager.convert(`${config.magento2.imgUrl}${item.block_product_d.data.product[storeCode].secondary_image}`, 'image', 'product') : null } : null,
                  ].filter(x => x),
                }
              })
            }
          } catch (error) {
            return {
              component: 'movement_selector_error'
            }
          }
        }



        case 'toolbar_anchor': {
          try {
            return {
              component: 'toolbar_anchor',

              items: slice.items.map((item) => {
                return {
                  text: item.block_text,
                }
              })
            }
          } catch (error) {
            return {
              component: 'toolbar_anchor_error'
            }
          }
        }



        case 'generic_grid': {
          try {
            return {
              component: 'generic_grid',
              declination: slice.primary.declination, // "expertise" existing so far
              item: {
                side: slice.primary.block_side,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                  filter: slice.primary.block_image_filter ? slice.primary.block_image_filter : ''
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                  filter: slice.primary.block_image_mobile_filter ? slice.primary.block_image_mobile_filter : ''
                } : null,
              },
              items: slice.items.map((item) => {
                return {
                  title: item.block_title,
                  headline: item.block_headline,
                  headlineColor: item.block_headline_color,
                  text: item.block_text,
                  ctaText: item.block_cta_text,
                  ctaLink: item.block_cta_link,
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                    filter: item.block_image_filter ? slice.primary.block_image_filter : ''
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height,
                    filter: item.block_image_mobile_filter ? item.block_image_mobile_filter : ''
                  } : null,
                }
              })
            }
          } catch (error) {
            return {
              component: 'generic_grid_error'
            }
          }
        }

        // case 'defy_5hz_dual_image': {
        //   try {
        //     return {
        //       headline: slice.primary.block_headline,
        //       title: slice.primary.block_title,
        //       text: slice.primary.block_text,
        //       items: slice.items.map(item => {
        //         return {
        //           image: item.block_image && item.block_image.url ? {
        //             url: ImageManager.convert(item.block_image.url),
        //             alt: item.block_image.alt,
        //           } : null,
        //           imageMobile: item.block_image && item.block_image.url ? {
        //             url: ImageManager.convert(item.block_image.url),
        //             alt: item.block_image.alt,
        //           } : null
        //         }
        //       })
        //     }
        //   } catch (error) {
        //     return {
        //       component: 'defy_5hz_dual_image_error'
        //     }
        //   }
        // }

        case 'commitment_hero': {
          try {
            return {
              component: 'full_width_tile',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                } : null,
                image_headline: slice.primary.image_headline,
                image_title: slice.primary.image_title,
                ctaLink: slice.primary.image_ctaLink,
                textColor: slice.primary.image_ctaColor,
                ctaText: slice.primary.image_ctaText,
              }
            }
          } catch (error) {
            return {
              component: 'full_width_tile'
            }
          }
        }

        case 'large_card': {
          try {
            return {
              component: 'dual_tile',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
              },
              items: slice.items.map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height,
                  } : null,
                  headline: item.block_headline,
                  ctaText: item.cta_text,
                  title: item.card_title,
                  text: item.card_text,
                  ctaLink: item.card_link
                }
              })
            }
          } catch (error) {
            return {
              component: 'error'
            }
          }
        }
        case 'small_card': {
          try {
            return {
              component: 'list_tile',
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                ctaLink: slice.primary.cta_link,
                ctaText: slice.primary.cta_text
              },
              items: slice.items.map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height,
                  } : null,
                  ctaText: item.cta_text,
                  title: item.card_title,
                  text: item.card_text,
                  ctaLink: item.card_link
                }
              })
            }
          } catch (error) {
            return {
              component: 'list_tile'
            }
          }
        }
        case 'large_highlights': {
          try {
            return {
              component: 'large_highlights',
              item: {
                hasBorder: slice.primary.has_border,
                declination: slice.primary.declination,
                title: slice.primary.block_title,
                text: slice.primary.block_text,
                side: slice.primary.block_side,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                } : null,
                products: [
                  slice.primary.block_product_a && slice.primary.block_product_a.data ? { routeParam: slice.primary.block_product_a.data.uid, ...slice.primary.block_product_a.data.product[storeCode] } : null,
                  slice.primary.block_product_b && slice.primary.block_product_b.data ? { routeParam: slice.primary.block_product_b.data.uid, ...slice.primary.block_product_b.data.product[storeCode] } : null,
                  slice.primary.block_product_c && slice.primary.block_product_c.data ? { routeParam: slice.primary.block_product_c.data.uid, ...slice.primary.block_product_c.data.product[storeCode] } : null,
                  slice.primary.block_product_d && slice.primary.block_product_d.data ? { routeParam: slice.primary.block_product_d.data.uid, ...slice.primary.block_product_d.data.product[storeCode] } : null,
                ].filter(x => x)
              },
              // items: slice.items.map(item => {
              //   return {
              //     image: item.block_image && item.block_image.url ? {
              //       url: ImageManager.convert(item.block_image.url),
              //       alt: item.block_image.alt,
              //       width: item.block_image.dimensions.width,
              //       height: item.block_image.dimensions.height,
              //     } : null,
              //     products: [
              //       item.block_product_a && item.block_product_a.data ? { routeParam: item.block_product_a.data.uid, ...item.block_product_a.data.product[storeCode] } : null,
              //       item.block_product_b && item.block_product_b.data ? { routeParam: item.block_product_b.data.uid, ...item.block_product_b.data.product[storeCode] } : null,
              //       item.block_product_c && item.block_product_c.data ? { routeParam: item.block_product_c.data.uid, ...item.block_product_c.data.product[storeCode] } : null,
              //       item.block_product_d && item.block_product_d.data ? { routeParam: item.block_product_d.data.uid, ...item.block_product_d.data.product[storeCode] } : null,
              //     ].filter(x => x)
              //   }
              // })
            }
          } catch (error) {
            return {
              component: 'error'
            }
          }
        }
        case 'small_highlights': {
          try {
            return {
              component: 'small_highlights',
              items: slice.items.map(item => {
                return ({
                  hasBorder: item.has_border,
                  title: item.block_title,
                  text: item.block_text,
                  ctaText: item.block_ctaText,
                  ctaLink: item.block_ctaLink,
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height,
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height,
                  } : null,
                })
              })
            }
          } catch (error) {
            return {
              component: 'error'
            }
          }
        }
        case 'slider_tabs_bar': {
          try {
            return {
              component: 'slider_tabs_bar',
              item: {
              },
              items: slice.items.map(item => {
                let content = item.details_link.data.content[0]
                return {
                  name: item.category_title,
                  headline: content.primary.block_headline,
                  title: content.primary.block_title,
                  text: content.primary.block_text,
                  queryParam: content.primary.query_param,
                  peoples: content.items.map(item => {
                    return {
                      headline: item.block_headline,
                      name: item.block_name,
                      title: item.block_title,
                      text: item.block_text ? PrismicDOM.RichText.asHtml(item.block_text) : item.block_text,
                      brandLink: componentProcessor(item.block_brand_page.data),
                      cta_bottom: item.cta_bottom,
                      cta_bottom_text: item.cta_bottom_text,
                      queryParam: item.query_param,
                      preview: {
                        image: item.block_image && item.block_image.url ? {
                          url: ImageManager.convert(item.block_image.url),
                          alt: item.block_image.alt,
                          width: item.block_image.dimensions.width,
                          height: item.block_image.dimensions.height,
                        } : null,
                        imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                          url: ImageManager.convert(item.block_image_mobile.url),
                          alt: item.block_image_mobile.alt,
                          width: item.block_image_mobile.dimensions.width,
                          height: item.block_image_mobile.dimensions.height,
                        } : null
                      },
                      banner: {
                        image: item.block_banner && item.block_banner.url ? {
                          url: ImageManager.convert(item.block_banner.url),
                          alt: item.block_banner.alt,
                          width: item.block_banner.dimensions.width,
                          height: item.block_banner.dimensions.height,
                        } : null,
                        imageMobile: item.block_banner_mobile && item.block_banner_mobile.url ? {
                          url: ImageManager.convert(item.block_banner_mobile.url),
                          alt: item.block_banner_mobile.alt,
                          width: item.block_banner_mobile.dimensions.width,
                          height: item.block_banner_mobile.dimensions.height,
                        } : null
                      },
                      right_tile: {
                        image: item.block_right_tile && item.block_right_tile.url ? {
                          url: ImageManager.convert(item.block_right_tile.url),
                          alt: item.block_right_tile.alt,
                          width: item.block_right_tile.dimensions.width,
                          height: item.block_right_tile.dimensions.height,
                        } : null,
                        imageMobile: item.block_right_tile_mobile && item.block_right_tile_mobile.url ? {
                          url: ImageManager.convert(item.block_right_tile_mobile.url),
                          alt: item.block_right_tile_mobile.alt,
                          width: item.block_right_tile_mobile.dimensions.width,
                          height: item.block_right_tile_mobile.dimensions.height,
                        } : null,
                        products: [
                          item.block_product_a && item.block_product_a.data ? { routeParam: item.block_product_a.data.uid, ...item.block_product_a.data.product[storeCode] } : null,
                          item.block_product_b && item.block_product_b.data ? { routeParam: item.block_product_b.data.uid, ...item.block_product_b.data.product[storeCode] } : null,
                          item.block_product_c && item.block_product_c.data ? { routeParam: item.block_product_c.data.uid, ...item.block_product_c.data.product[storeCode] } : null,
                          item.block_product_d && item.block_product_d.data ? { routeParam: item.block_product_d.data.uid, ...item.block_product_d.data.product[storeCode] } : null,
                        ].filter(x => x)
                      },
                      left_tile: {
                        image: item.block_left_tile_image && item.block_left_tile_image.url ? {
                          url: ImageManager.convert(item.block_left_tile_image.url),
                          alt: item.block_left_tile_image.alt,
                          width: item.block_left_tile_image.dimensions.width,
                          height: item.block_left_tile_image.dimensions.height,
                        } : null,
                        imageMobile: item.block_left_tile_image_mobile && item.block_left_tile_image_mobile.url ? {
                          url: ImageManager.convert(item.block_left_tile_image_mobile.url),
                          alt: item.block_left_tile_image_mobile.alt,
                          width: item.block_left_tile_image_mobile.dimensions.width,
                          height: item.block_left_tile_image_mobile.dimensions.height,
                        } : null,
                        video: item.block_left_tile_video && item.block_left_tile_video.url ? {
                          url: ImageManager.convert(item.block_left_tile_video.url, 'video'),
                          alt: item.block_left_tile_video.name,
                          coverUrl: item.block_left_tile_image && item.block_left_tile_image.url ? ImageManager.convert(item.block_left_tile_image.url) : null,
                          coverAlt: item.block_left_tile_image ? item.block_left_tile_image.alt : null,
                          coverWidth: item.block_left_tile_image.dimensions.width,
                          coverHeight: item.block_left_tile_image.dimensions.height,
                          subtitle: {
                            label: storeView.i18n.defaultLanguage,
                            srclang: storeView.i18n.defaultLocale.split('-')[0],
                            src: item.block_left_tile_video_subtitles ? ImageManager.convert(item.block_left_tile_video_subtitles, 'video') : '',
                          }
                        } : null
                      }
                    }
                  })
                }
              })
            }
          } catch (error) {
            return {
              component: 'slider_tabs_bar'
            }
          }
        }
        case 'timeline_decade': {
          try {
            return {
              component: 'timeline_decade',
              item: {
                title: slice.primary.block_title,
                headline: slice.primary.block_headline,
                text: slice.primary.block_text,
                background: slice.primary.block_background === 'dark' ? 'dark' : 'light',
                side: slice.primary.block_side === 'right' ? 'right' : 'left',
                watermark: slice.primary.watermark,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                } : null,
              },
            }
          } catch (error) {
            return {
              component: 'timeline_decade_error'
            }
          }
        }

        case 'timeline_year': {
          try {
            return {
              component: 'timeline_year',
              item: {
                background: slice.primary.block_background === 'dark' ? 'dark' : 'light',
                side: slice.primary.block_side === 'right' ? 'right' : 'left',
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url && slice.primary.block_video.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name,
                } : null,
                subtitle: {
                  label: storeView.i18n.defaultLanguage,
                  srclang: storeView.i18n.defaultLocale.split('-')[0],
                  src: slice.primary.block_video_subtitle ? ImageManager.convert(slice.primary.block_video_subtitle.url, 'video') : null,
                },
                type: slice.primary.block_video && slice.primary.block_video.url ? 'video' : 'image',
              },
              items: slice.items.map(item => {
                return {
                  title: item.block_title,
                  headline: item.block_headline,
                  text: item.block_text,
                }
              })
            }
          } catch (error) {
            return {
              component: 'timeline_year_error'
            }
          }
        }

        case 'timeline_toolbar': {
          try {
            return {
              component: 'timeline_toolbar',
              item: {
                declination: slice.primary.declination
              },
              items: slice.items.map(item => {
                return {
                  text: item.block_text,
                  anchor: item.block_anchor
                }
              })
            }
          } catch (error) {
            return {
              component: 'timeline_toolbar_error'
            }
          }
        }

        case 'adaptive_image': {
          try {
            return {
              component: 'adaptive_image',
              item: {
                title: slice.primary.block_title,
                headline: slice.primary.block_headline,
                ctaText: slice.primary.cta_label,
                ctaLink: slice.primary.cta_link ? slice.primary.cta_link : '',
                textColor: slice.primary.block_text_color_desktop,
                textColorMobile: slice.primary.block_text_color_mobile,
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height,
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height,
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url && slice.primary.block_video.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name,
                } : null,
                videoMobile: slice.primary.block_video_mobile && slice.primary.block_video_mobile.url && slice.primary.block_video_mobile.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video_mobile.url, 'video'),
                  alt: slice.primary.block_video_mobile.name,
                } : null,
                impolicy: slice.primary.impolicy,
                products: slice.items.filter(item => item.product && item.product.data && item.product.data.product)
                  .map(item => { return item.product.data.product[storeCode] })
              },
            }
          } catch (error) {
            return {
              component: 'adaptive_image_error'
            }
          }
        }

        case 'category_block': {
          try {
            return {
              component: 'category_block',
              data: slice,
              items: slice.items
                .map((item) => ({
                  items: item.category_field.data.content[0].items,
                  item: {
                    title: item.category_field.data.content[0].primary.block_title
                  }
                }))
                .map(item => {
                  const items = item.items.map(y => {
                    const components = componentProcessor(y.page_brand.data).content
                    const backgroundColor = y.background_color

                    const news = Object
                      .keys(y)
                      .filter((key) => key.includes('news'))
                      .sort((a, b) => a > b ? 1 : -1)
                      .map(key => y[key])
                      .filter(x => x)
                      .map(item => item.data)
                      .filter(x => x)
                      .map(x => ({
                        title: x.title,
                        image: {
                          ...x.image,
                          url: ImageManager.convert(x.image.url),
                        },
                        date: x.date,
                        uid: x.uid
                      }))
                        
                    const newsColors = Object
                      .keys(y)
                      .filter((key) => key.includes('color')) 
                      .sort((a, b) => a > b ? 1 : -1)
                      .map(key => y[key])

                    const aggregation = [...news.map((x, i) => ({ ...x, textColor: newsColors[i] }))]

                    return { component: 'news_tile', declination: y.declination, item: { backgroundColor }, items: aggregation, components }
                  })
                  return { items, item: item.item }
                })
            }
          } catch (error) {
            return {
              component: 'category_block_error'
            }
          }
        }

        case 'ambassadors_tile': {
          try {
            return {
              component: slice.slice_type,
              item: {
                headline: slice.primary.block_headline,
                title: slice.primary.block_title,
                subtitle: slice.primary.block_subtitle,
                text: PrismicDOM.RichText.asHtml(slice.primary.block_text),
                side: slice.primary.block_side,
                ctaLink: slice.primary.block_cta_link,
                ctaText: slice.primary.block_cta_text,
                video: slice.primary.block_video && slice.primary.block_video.url && slice.primary.block_video.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                anchor: slice.primary.block_anchor
              },
              images: slice.items.filter(item => item.block_image && item.block_image.url).map(item => {
                return {
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height
                  } : null,
                }
              }),
              products: slice.items.filter(item => item.product && item.product.data && item.product.data.product).map(item => { return item.product.data.product[storeCode] })
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }
        case 'ambassadors_slider': {
          try {
            return {
              component: slice.slice_type,
              item: {
                declination: slice.primary.block_declination,
              },
              items: slice.items.map(item => {
                return {
                  headline: item.block_headline,
                  title: item.block_title,
                  subtitle: item.block_subtitle,
                  text: PrismicDOM.RichText.asHtml(item.block_text),
                  anchor_id: item.block_anchor_id,
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height
                  } : null,
                }
              })
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'generic_toolbar': {
          try {
            return {
              component: slice.slice_type,
              item: {
                stickyOnScroll: slice.primary.sticky_on_scroll
                  ? slice.primary.sticky_on_scroll
                  : false
              },
              items: slice.items.map(item => {
                return {
                  url: item.block_link,
                  text: item.block_text
                }
              })
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'news_promote': {
          try {
            return {
              component: 'news_promote',
              items: Object
                .keys(slice.primary)
                .filter(key => key.includes('news'))
                .map(key => slice.primary[key])
                .map(({ uid, data }) => ({
                  date: data.date, title: data.title, uid, image: {
                    url: ImageManager.convert(data.image.url),
                    alt: data.image.alt,
                    width: data.image.dimensions.width,
                    height: data.image.dimensions.width,
                  }
                }))
            }
          } catch (error) {
            return {
              component: 'news_promote_error'
            }
          }
        }
        case 'two_large_images': {
          try {
            return {
              component: slice.slice_type,
              item: {
                // declination: slice.primary.block_declination,
              },
              items: slice.items.map(item => {
                return {
                  link: {
                    text: item.block_link_text,
                    url: item.block_link_url,
                  },
                  icon: item.block_icon,
                  image: item.block_image && item.block_image.url ? {
                    url: ImageManager.convert(item.block_image.url),
                    alt: item.block_image.alt,
                    width: item.block_image.dimensions.width,
                    height: item.block_image.dimensions.height
                  } : null,
                  imageMobile: item.block_image_mobile && item.block_image_mobile.url ? {
                    url: ImageManager.convert(item.block_image_mobile.url),
                    alt: item.block_image_mobile.alt,
                    width: item.block_image_mobile.dimensions.width,
                    height: item.block_image_mobile.dimensions.height
                  } : null,
                  video: item.block_video && item.block_video.url && item.block_video.kind === 'document' ? {
                    url: ImageManager.convert(item.block_video.url, 'video'),
                    alt: item.block_video.name
                  } : null,
                  products: Object
                    .keys(item)
                    .filter(key => key.includes('block_product'))
                    .map(key => item[key])
                    .filter(product => product && product.data && product.data.product && product.data.product[storeCode])
                    .map(product => product.data.product[storeCode])
                    .map(({ price, name, sku, url_key, image, secondary_image }) => {
                      return {
                        price,
                        name,
                        sku,
                        url_key,
                        image: image ? ImageManager.convert(`${config.magento2.imgUrl}${image}`, 'image', 'product') : null,
                        imageHover: secondary_image ? ImageManager.convert(`${config.magento2.imgUrl}${secondary_image}`, 'image', 'product') : null,
                      }
                    })
                }
              })
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'icons_slider_banner': {
          try {
            return {
              component: slice.slice_type,
              item: {
                image: slice.primary.block_image && slice.primary.block_image.url ? {
                  url: ImageManager.convert(slice.primary.block_image.url),
                  alt: slice.primary.block_image.alt,
                  width: slice.primary.block_image.dimensions.width,
                  height: slice.primary.block_image.dimensions.height
                } : null,
                imageMobile: slice.primary.block_image_mobile && slice.primary.block_image_mobile.url ? {
                  url: ImageManager.convert(slice.primary.block_image_mobile.url),
                  alt: slice.primary.block_image_mobile.alt,
                  width: slice.primary.block_image_mobile.dimensions.width,
                  height: slice.primary.block_image_mobile.dimensions.height
                } : null,
                video: slice.primary.block_video && slice.primary.block_video.url && slice.primary.block_video.kind === 'document' ? {
                  url: ImageManager.convert(slice.primary.block_video.url, 'video'),
                  alt: slice.primary.block_video.name
                } : null,
                title: slice.primary.block_title,
              },
              items: slice.items.map(item => {
                let { sku, name, status, price, image, secondary_image, url_key } = item.product.data.product[storeCode]
                return {
                  routeParam: url_key,
                  sku,
                  name,
                  status,
                  price,
                  image: image ? ImageManager.convert(`${config.magento2.imgUrl}${image}`, 'image', 'product') : null,
                  imageHover: secondary_image ? ImageManager.convert(`${config.magento2.imgUrl}${secondary_image}`, 'image', 'product') : null,
                  // raw: item.product.data.product[storeCode]
                }
              })
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'scroll_animation': {
          try {
            return {
              component: slice.slice_type,
              item: {
                text: Object.keys(slice.primary).filter(key => key.includes('text')).map(key => slice.primary[key])
              }
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'pages_disallowed': {
          try {
            return {
              component: slice.slice_type,
              items: slice.items.map(item => {
                return {
                  page: item.page_disallowed || null
                }
              })
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'anchor': {
          try {
            return {
              component: slice.slice_type,
              item: {
                identifier: slice.primary.identifier
              }
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'editorial_highlight': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                ctaText: slice.primary.cta_label,
                ctaLink: slice.primary.cta_link ? slice.primary.cta_link : '',
                images: [
                  {
                    url: ImageManager.convert(slice.primary.block_image1.url),
                    alt: slice.primary.block_image1.alt,
                  },
                  {
                    url: ImageManager.convert(slice.primary.block_image2.url),
                    alt: slice.primary.block_image2.alt,
                  },
                  {
                    url: ImageManager.convert(slice.primary.block_image3.url),
                    alt: slice.primary.block_image3.alt,
                  }
                ],
                userTypeVisibility: slice.primary.user_type_visibility,
                visitsBeforeReturning: slice.primary.visits_before_returning
              }
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'editorial_breadcrumb': {
          try {
            return {
              component: slice.slice_type,
              item: {
                invert: slice.primary.invert
              },
              items: slice.items.map(item => {
                return {
                  path: item.block_link,
                  name: item.block_text,
                  include_in_menu: true
                }
              })
            }
          } catch (error) {
            return {
              component: slice.slice_type + '_error'
            }
          }
        }

        case 'images_tab_accordion': {
          try {
            return {
              component: slice.slice_type,
              item: {
                title: slice.primary.block_title,
                showCta: slice.primary.block_show_cta
              },
              items: slice.items.map(item => ({
                title: item.block_tab_title,
                image: item.block_tab_image && item.block_tab_image.url ? {
                  url: ImageManager.convert(item.block_tab_image.url),
                  alt: item.block_tab_image.alt,
                  width: item.block_tab_image.dimensions.width,
                  height: item.block_tab_image.dimensions.height
                } : null,
                imageMobile: item.block_tab_image_mobile && item.block_tab_image_mobile.url ? {
                  url: ImageManager.convert(item.block_tab_image_mobile.url),
                  alt: item.block_tab_image_mobile.alt,
                  width: item.block_tab_image_mobile.dimensions.width,
                  height: item.block_tab_image_mobile.dimensions.height
                } : null
              }))
            }
          } catch (error) {
            return {
              component: 'images_tab_accordion_error'
            }
          }
        }

        default: return { component: 'inexistant_component_error', name: slice.slice_type }
      }
    })

  const seoIndexMap = {
    'Add to sitemap': 'sitemap',
    'Links on this page will be followed by search engines': 'links',
    'Do not index this page': 'noindex'
  }

  const twitterCardMap = {
    'Summary Card': 'summary',
    'Summary Card with Large Image': 'summary_large_image',
    'App Card': 'app',
    'Player Card': 'player'
  }

  const seo = {
    title: page.seo_title ? page.seo_title : page.title,
    description: page.seo_description ? page.seo_description : page.description,
    index: seoIndexMap[page.seo_index]
  }

  const openGraph = {
    title: page.open_graph_title ? page.open_graph_title : page.seo_title ? page.seo_title : page.title,
    description: page.open_graph_description ? page.open_graph_description : page.seo_description ? page.seo_description : page.description,
    type: page.open_graph_type,
    image: {
      url: page.open_graph_image ? page.open_graph_image.url : null,
      alt: page.open_graph_image && page.open_graph_image.alt ? page.open_graph_image.alt : page.open_graph_title ? page.open_graph_title : page.seo_title ? page.seo_title : page.title,
      width: page.open_graph_image && page.open_graph_image.dimensions ? page.open_graph_image.dimensions.width : null,
      height: page.open_graph_image && page.open_graph_image.dimensions ? page.open_graph_image.dimensions.height : null
    }
  }

  const twitter = {
    type: twitterCardMap[page.twitter_card_type],
    title: page.twitter_title ? page.twitter_title : page.open_graph_title ? page.open_graph_title : page.seo_title ? page.seo_title : page.title,
    description: page.twitter_description ? page.twitter_description : page.open_graph_description ? page.open_graph_description : page.seo_description ? page.seo_description : page.description,
    image: {
      url: page.twitter_image ? page.twitter_image.url : null,
      alt: page.twitter_image && page.twitter_image.alt ? page.twitter_image.alt : page.twitter_title ? page.twitter_title : page.open_graph_title ? page.open_graph_title : page.seo_title ? page.seo_title : page.title,
      width: page.twitter_image && page.twitter_image.dimensions ? page.twitter_image.dimensions.width : null,
      height: page.twitter_image && page.twitter_image.dimensions ? page.twitter_image.dimensions.height : null,
    }

  }

  const konami = {
    codes: getKonamiCodes(page),
    code: page.konami_code,
    headline: page.konami_headline,
    title: page.konami_title,
    text: page.konami_text,
    available_date: page.konami_available_date,
    image: page.konami_image && page.konami_image.dimensions ? {
      url: ImageManager.convert(page.konami_image.url),
      alt: page.konami_image.alt,
      width: page.konami_image.dimensions.width,
      height: page.konami_image.dimensions.height,
    } : {
      url: null,
      alt: 'konami_image',
      width: 0,
      height: 0,
    }
  }

  const main: any = {}
  try {
    for (const prop in page) {
      if (page.hasOwnProperty(prop)) {
        if (
          !prop.includes('seo') &&
          !prop.includes('twitter') &&
          !prop.includes('open_graph') &&
          !prop.includes('content') &&
          !prop.includes('board')
        ) {
          if (prop === 'image') {
            main[prop] = {
              alt: page['image'].alt,
              url: page['image'].url,
              height: page['image'].dimensions.height,
              width: page['image'].dimensions.width,
              filter: page['image_filter'] ? page['image_filter'] : ''
            }
          } else if (prop === 'image_mobile') {
            main['imageMobile'] = {
              alt: page['image_mobile'].alt,
              url: page['image_mobile'].url,
              height: page['image_mobile'].dimensions.height,
              width: page['image_mobile'].dimensions.width,
              filter: page['image_filter'] ? page['image_filter'] : ''
            }
          } else if (prop === 'excerpt') {
            main['excerpt'] = page['excerpt'] ? PrismicDOM.RichText.asHtml(page['excerpt']) : null
          } else if (prop === 'date') {
            main['date'] = page['date'] ? new Date(page['date']).toLocaleDateString(locale) : null
          } else {
            main[prop] = page[prop]
          }
        }
      }
    }
  } catch (error) {
    console.log('[Primsic] Main content couldn\'nt be builded correctly', error)
  }

  let livetour = {}
  try {
    livetour = { iFrameUrl: page.iframe_url }
  }
  catch (error) {
    console.log(`[Prismic] Landing Livetour couldn't be built (we may not be on this page)`, error)
  }

  let pantone = {}
  try {
    const { sku, name, status, price, image, secondary_image } = page.product.data.product[storeCode]
    pantone = {
      sticky: {
        title: page.page_title
      },
      header: {
        title: page.header_title,
        text: page.header_text
      },
      artwork: {
        title: page.artwork_title,
        text: page.artwork_text
      },
      clockwise: {
        title: page.clockwise_title,
        text: page.clockwise_text
      },
      case: {
        title: page.case_title,
        text: page.case_text
      },
      indexes: {
        title: page.indexes_title,
        text: page.indexes_text
      },
      movement: {
        front: {
          top: {
            left: {
              title: page.movement_front_top_left_title,
              text: page.movement_front_top_left_text
            },
            right: {
              title: page.movement_front_top_right_title,
              text: page.movement_front_top_right_text
            }
          },
          bottom: {
            left: {
              title: page.movement_front_bottom_left_title,
              text: page.movement_front_bottom_left_text
            },
            right: {
              title: page.movement_front_bottom_right_title,
              text: page.movement_front_bottom_right_text
            }
          }
        },
      },
      editorial: {
        title: page.editorial_title,
        introduction: page.editorial_introduction,
        top: {
          left: {
            title: page.editorial_top_left_title,
            text: page.editorial_top_left_text,
            image: page.editorial_top_left_image && page.editorial_top_left_image.url ? {
              url: ImageManager.convert(page.editorial_top_left_image.url),
              alt: page.editorial_top_left_image.alt,
              width: page.editorial_top_left_image.dimensions.width,
              height: page.editorial_top_left_image.dimensions.height,
            } : null,
          },
          right: {
            title: page.editorial_top_right_title,
            text: page.editorial_top_right_text,
            image: page.editorial_top_right_image && page.editorial_top_right_image.url ? {
              url: ImageManager.convert(page.editorial_top_right_image.url),
              alt: page.editorial_top_right_image.alt,
              width: page.editorial_top_right_image.dimensions.width,
              height: page.editorial_top_right_image.dimensions.height,
            } : null,
          }
        },
        main: {
          text: page.editorial_main_text
        },
        video: page.editorial_video && page.editorial_video.url && page.editorial_video.kind === 'document' ? {
          url: ImageManager.convert(page.editorial_video.url, 'video'),
          alt: page.editorial_video.name
        } : null,
        cover: page.editorial_cover && page.editorial_cover.url ? {
          url: ImageManager.convert(page.editorial_cover.url),
          alt: page.editorial_cover.alt
        } : null,
        subtitle: {
          label: storeView.i18n.defaultLanguage,
          srclang: storeView.i18n.defaultLocale.split('-')[0],
          src: page.editorial_subtitle ? ImageManager.convert(page.editorial_subtitle.url, 'video') : null,
        },
        center: {
          right: {
            title: page.editorial_center_right_title,
            text: page.editorial_center_right_text,
            image: page.editorial_center_right_image && page.editorial_center_right_image.url ? {
              url: ImageManager.convert(page.editorial_center_right_image.url),
              alt: page.editorial_center_right_image.alt,
              width: page.editorial_center_right_image.dimensions.width,
              height: page.editorial_center_right_image.dimensions.height,
            } : null,
          }
        },
        bottom: {
          left: {
            title: page.editorial_bottom_left_title,
            text: page.editorial_bottom_left_text,
            image: page.editorial_bottom_left_image && page.editorial_bottom_left_image.url ? {
              url: ImageManager.convert(page.editorial_bottom_left_image.url),
              alt: page.editorial_bottom_left_image.alt,
              width: page.editorial_bottom_left_image.dimensions.width,
              height: page.editorial_bottom_left_image.dimensions.height,
            } : null,
          },
          right: {
            title: page.editorial_bottom_right_title,
            text: page.editorial_bottom_right_text,
            image: page.editorial_bottom_right_image && page.editorial_bottom_right_image.url ? {
              url: ImageManager.convert(page.editorial_bottom_right_image.url),
              alt: page.editorial_bottom_right_image.alt,
              width: page.editorial_bottom_right_image.dimensions.width,
              height: page.editorial_bottom_right_image.dimensions.height,
            } : null,
          }
        }
      },
      // product: page.product
      product: {
        routeParam: page.product.uid,
        sku,
        name,
        status,
        price,
        image: ImageManager.convert(`${config.magento2.imgUrl}${image}`, 'image', 'product'),
        imageHover: (secondary_image) ? ImageManager.convert(`${config.magento2.imgUrl}${secondary_image}`, 'image', 'product') : null,
        raw: page.product[storeCode]
      }
    }
  } catch (error) {
    console.log(`[Prismic] Landing Pantone couldn't be built (we may not be on this page) `, error)
  }

  const getUrlKey = (name, sku) => {
    return name.toLowerCase().replace(/ /g, '-') + '-' + sku.toLowerCase().replace(/\./g, '-').replace(/\//g, '-')
  }

  const productslist = Object.entries(page)
  .filter(([key, value]) => key.split('board_8_product_').length > 1 && value !== null)
  .map(([key, value]) => {
    const product = value[storeCode]
    return {
      ...product,
      routeParam: product.url_key
    }
  })

  let boards = {}
  try {
    boards = {
      watch: {
        ...page.featured_watch[storeCode],
        routeParam: page.featured_watch[storeCode].url_key
      },
      header: {
        leftText: page.header_left_text,
        centerText: page.header_center_text,
        ctaText: page.header_cta_text,
      },
      board1: {
        title_1: page.board_1_title_1,
        text_1: page.board_1_text_1
      },
      board2: {
        title_1: page.board_2_title_1,
        text_1: page.board_2_text_1,
        title_2: page.board_2_title_2,
        title_3: page.board_2_title_3,
        title_4: page.board_2_title_4,
        title_5: page.board_2_title_5,
        text_5: page.board_2_text_5, // missing in component
        title_6: page.board_2_title_6,
        text_6: page.board_2_text_6,
        title_7: page.board_2_title_7,
        text_7: page.board_2_text_7,
        title_8: page.board_2_title_8,
        text_8: page.board_2_text_8,
      },
      board3: {
        title_1: page.board_3_title_1,
        text_1: page.board_3_text_1,
        title_2: page.board_3_title_2,
        text_2: page.board_3_text_2,
        title_3: page.board_3_title_3,
        title_4: page.board_3_title_4,
        text_4: page.board_3_text_4,
        title_5: page.board_3_title_5,
        title_6: page.board_3_title_6,
      },
      board4: {
        cover: page.board_4_image ? page.board_4_image.url : null,
        video: page.board_4_video ? page.board_4_video.url : null,
        subtitle: page.board_4_subtitle ? page.board_4_subtitle.url : null
      },
      board5: {
        title_1: page.board_5_title_1,
        title_2: page.board_5_title_2,
        title_3: page.board_5_title_3,
        text_3: page.board_5_text_3,
      },
      board6: {
        title_1: page.board_6_title_1,
        text_1: page.board_6_text_1
      },
      board7: {
        title_1: page.board_7_title_1,
        text_1: page.board_7_text_1
      },
      board8: {
        title_1: page.board_8_title_1,
        text_1: page.board_8_text_1,
        products: productslist,
        // products: [
        // {
        //   ...page.board_8_product_1[storeCode],
        //   routeParam: page.board_8_product_1[storeCode].url_key
        // },
        // {
        //   ...page.board_8_product_2[storeCode],
        //   routeParam: page.board_8_product_2[storeCode].url_key
        // }, {
        //   ...page.board_8_product_3[storeCode],
        //   routeParam: page.board_8_product_3[storeCode].url_key
        // },
        // {
        //   ...page.board_8_product_4[storeCode],
        //   routeParam: page.board_8_product_4[storeCode].url_key
        // }
        // ],
      },
      board9: {
        title_1: page.board_9_title_1,
        subtitle_1: page.board_9_subtitle_1,
        title_2: page.board_9_title_2,
        text_2: page.board_9_text_2,
        title_3: page.board_9_title_3,
        text_3: page.board_9_text_3,
      }
    }
  } catch (error) {
    console.log('[Prismic] Chronomaster Landing boards couldn\'nt be builded correctly (we may not be on this page)', error)
  }

  main['headerTextColor'] = page.header_text_color
  return { main, content, seo, openGraph, twitter, konami, boards, pantone, livetour }
}

/**
 * Meta data builder
 * @param page Prismic page date
 * @param route Given route 
 * @param alternateLanguages Alternates languages for the given route (i.e. /products/my-uid) contributed on Prismic
 * @returns meta
 */
export const metaInfoBuilder = (page: any, route: { fullPath: string, path: string }, alternateLanguages) => {
  const { url: currentUrl } = currentStoreView()

  let defaultLang = ''
  let hreflangs = []
  if (alternateLanguages) {
    hreflangs = alternateLanguages
      .map(({ lang }) => ({
        vmid: 'hreflang-' + lang,
        rel: 'alternate',
        hreflang: lang,
        href: config.client.url + route.fullPath.replace(currentUrl, '/' + lang.replace('-', '_'))
      }))

    if (alternateLanguages.find(({ lang }) => lang === 'en-us')) {
      hreflangs = hreflangs.filter(({ hreflang }) => hreflang !== 'en-us')
      defaultLang = 'en-us'
    }
    else if (alternateLanguages.find(({ lang }) => lang === 'en-en')) {
      hreflangs = hreflangs.filter(({ hreflang }) => hreflang !== 'en-en')
      defaultLang = 'en-en'
    }
    else if (alternateLanguages.find(({ lang }) => lang === 'en-gb')) {
      hreflangs = hreflangs.filter(({ hreflang }) => hreflang !== 'en-gb')
      defaultLang = 'en-gb'
    }
  }
  else {
    alternateLanguages = []
  }
  if (config.prismic.hreflangExclusions) {
    hreflangs = hreflangs.filter(({ hreflang }) => !config.prismic.hreflangExclusions.includes(hreflang))
  }

  const storeView = currentStoreView()

  const selfHrefLang = {
    vmid: 'hreflang-' + storeView.url.replace('/', '').replace('_', '-').split('-').map((x, i) => i === 1 ? x.toLowerCase() : x).join('-'),
    rel: 'alternate',
    hreflang: storeView.url.replace('/', '').replace('_', '-').toLowerCase(),
    href: config.client.url + route.fullPath.replace(currentUrl, '/' + storeView.url.replace('/', ''))
  }

  const globaleHreflangs = Object
    .values(config.storeViews)
    .filter((value: any) => value && value.i18n && (value.i18n.defaultLocale.toLowerCase() === 'en-ge' || value.i18n.defaultLocale.toLowerCase() === 'fr-ge') && value.storeCode === 'globale')
    .map((value: any) => {
      return {
        vmid: 'hreflang-' + value.url.replace('/', '').replace('_', '-').split('-').map((x, i) => i === 1 ? x.toLowerCase() : x).join('-'),
        rel: 'alternate',
        hreflang: value.url.replace('/', '').replace('_', '-').toLowerCase(),
        href: config.client.url + route.fullPath.replace(currentUrl, '/' + value.url.replace('/', ''))
      }
    })

  const defaultLocaleHreflangs = Object
    .values(config.storeViews)
    .filter((value: any) => value && value.storeCode != 'int')
    .filter((value: any) => value && value.i18n && (value.i18n.defaultLocale.toLowerCase() === 'en-us' || value.i18n.defaultLocale.toLowerCase() === 'en-en' || value.i18n.defaultLocale.toLowerCase() === 'en-gb'))
    .map((value: any) => {
      return {
        vmid: 'hreflang-' + value.url.replace('/', '').replace('_', '-').split('-').map((x, i) => i === 1 ? x.toLowerCase() : x).join('-'),
        rel: 'alternate',
        hreflang: value.url.replace('/', '').replace('_', '-').toLowerCase(),
        href: config.client.url + route.fullPath.replace(currentUrl, '/' + value.url.replace('/', ''))
      }
    })[0]

  const zhcnHreflangs = Object
    .values(config.storeViews)
    .filter((value: any) => value && value.i18n && (value.i18n.defaultLocale.toLowerCase() === 'zh-cn') && value.storeCode != 'zh_cn')
    .map((value: any) => {
      return {
        vmid: 'hreflang-' + value.url.replace('/', '').replace('_', '-').split('-').map((x, i) => i === 1 ? x.toLowerCase() : x).join('-'),
        rel: 'alternate',
        hreflang: value.url.replace('/', '').replace('_', '-').toLowerCase(),
        href: config.client.url + route.fullPath.replace(currentUrl, '/' + value.url.replace('/', ''))
      }
    })

  const zhhkHreflangs = Object
    .values(config.storeViews)
    .filter((value: any) => value && value.i18n && (value.i18n.defaultLocale.toLowerCase() === 'zh-hk') && value.storeCode != 'zh_hk')
    .map((value: any) => {
      return {
        vmid: 'hreflang-' + value.url.replace('/', '').replace('_', '-').split('-').map((x, i) => i === 1 ? x.toLowerCase() : x).join('-'),
        rel: 'alternate',
        hreflang: value.url.replace('/', '').replace('_', '-').toLowerCase(),
        href: config.client.url + route.fullPath.replace(currentUrl, '/' + value.url.replace('/', ''))
      }
    })


  hreflangs = [
    ...hreflangs,
    ...alternateLanguages.map(({ lang }) => lang.toLowerCase()).includes('en-ge') ? globaleHreflangs : [],
    alternateLanguages.map(({ lang }) => lang.toLowerCase()).includes('en-us') ? defaultLocaleHreflangs : [],
    ...alternateLanguages.map(({ lang }) => lang.toLowerCase()).includes('zh-cn') ? zhcnHreflangs : [],
    ...alternateLanguages.map(({ lang }) => lang.toLowerCase()).includes('zh-hk') ? zhhkHreflangs : [],
    selfHrefLang
  ].filter(({ hreflang }) => hreflang !== 'globale')


  return {
    title: page.seo.title,
    meta: [
      { vmid: 'description', name: 'description', content: page.seo.description },
      { vmid: 'og:type', property: 'og:type', content: page.openGraph.type },
      { vmid: 'og:title', property: 'og:title', content: page.openGraph.title },
      { vmid: 'og:description', property: 'og:description', content: page.openGraph.description },
      { vmid: 'og:image', property: 'og:image', content: page.openGraph.image.url },
      { vmid: 'og:image:width', property: 'og:image:width', content: page.openGraph.image.width },
      { vmid: 'og:image:height', property: 'og:image:height', content: page.openGraph.image.height },
      { vmid: 'og:image:alt', property: 'og:image:alt', content: page.openGraph.image.alt },
      { vmid: 'og:url', property: 'og:url', content: config.client.url + route.fullPath },
      { vmid: 'twitter:title', name: 'twitter:card', content: page.twitter.type },
      { vmid: 'twitter:title', name: 'twitter:title', content: page.twitter.title },
      { vmid: 'twitter:description', name: 'twitter:description', content: page.twitter.description },
      { vmid: 'twitter:image', name: 'twitter:image', content: page.twitter.image.url },
      { vmid: 'twitter:image:alt', name: 'twitter:image:alt', content: page.twitter.image.alt }
    ],
    link: defaultLang === '' ? [
      ...hreflangs,
      {
        vmid: 'hreflang',
        rel: 'canonical',
        href: config.client.url + route.fullPath
      }
    ] : [
      ...hreflangs,
      {
        vmid: 'hreflang',
        rel: 'alternate',
        hreflang: 'x-default',
        href: config.client.url + route.fullPath.replace(currentUrl, '/' + 'int')
      },
      , {
        vmid: 'hreflang',
        rel: 'canonical',
        href: config.client.url + route.fullPath
      }
    ]
  }
}

export const jsonLdBuilder = (page: any, route: { fullPath: string }) => {
  const { url: currentUrl } = currentStoreView()
  let product = null
  if (page && page.main && page.main.product && page.main.product.product && page.main.product.product[currentStoreView().storeCode]) {
    product = page.main.product.product[currentStoreView().storeCode]
  }

  if (!product) return {}

  let isInStock = false

  if (product && product.stock && product.stock.is_in_stock) {
    isInStock = true
  }

  return {
    '@context': 'http://schema.org',
    '@type': 'Product',
    description: product.description ? product.description.replace(/\|\|/g, '. ') : '',
    image: ImageManager.convert(`${config.magento2.imgUrl}${product.image}`, 'image', 'product'),
    itemCondition: 'http://schema.org/NewCondition',
    name: product.name,
    category: product.option_value_line,
    productID: product.id,
    sku: product.sku,
    brand: {
      '@type': 'Brand',
      'name': 'Zenith'
    },
    offers: {
      '@type': 'Offer',
      url: currentStoreView().url,
      priceCurrency: currentStoreView().i18n.currencyCode,
      price: product.price,
      itemCondition: 'https://schema.org/NewCondition',
      availability: isInStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      seller: {
        '@type': 'Organization',
        name: 'Zenith Watches'
      }
    }
  }
}

export const kebabToSnake = str => {
  return str.replace(/-/g, '_')
}

const getKonamiCodes = (page) => {
  if (!page.konami_code) {
    return []
  }
  const konamiCodes = [
    page.konami_code.toLowerCase()
  ]
  const maximumLimit = 4
  for (let i = 2; i <= maximumLimit; i++) {
    const code = page[`konami_code_${i}`];
    if (code) {
      konamiCodes.push(code.toLowerCase())
    }
  }
  return konamiCodes
}